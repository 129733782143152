/*.sidebar-container {
    width: 230px;
    position: fixed;
    z-index: 10;
}*/
.sidebar-container {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
}
.sidebar-content {
    background-color: #3b3b3b;
    height: 100%;
    &.emition {
        width: 64px;
        ul { padding-inline-start: 20px; }
    }
    .menu-ul {
        margin: 0;
        padding: 20px;
        height: 100vh;
    }
    ul {
        li {
            // position: relative;
            list-style: none;
            margin-bottom: 20px;
            cursor: pointer;
            &:hover { color: #fff; }
            &:hover svg{ path { fill: #fff; } }
            &:hover .warning { path { fill: red; } }
            &:hover .warning { path { fill: red; } }
            a.admin {color: #FA774D }
            button, a{
                display: flex;
                width: 100%;
                align-items: center;
                background-color: transparent;
                border: none;
                appearance: none;
                -webkit-appearance: none;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.2px;
                color: #c4c4c4;
                text-decoration: none;
                margin: 0;
                padding: 0;
                outline: none;
                text-align: initial;
                span {
                    flex-shrink: 1;
                    flex-grow: 1;
                    text-align: left;
                }
                svg {
                    flex-shrink: 0;
                    flex-grow: 0;
                    margin-right: 8px;
                }
                .closedesple {
                    transform: rotate(90deg);
                }
                &.active {
                    color: #fff;
                    svg{ path { fill: #fff; } }
                    .warning { path { fill: red; } }
                    .closedesple{
                        transition: transform .35s;
                        transform: rotate(180deg);
                    }
                }
                &.active {
                    color: #fff;
                    svg { path: { fill: #fff; } }
                    .warning { path { fill: red; } }
                    .closedesple {
                        transition: transform .35s;
                        transform: rotate(180deg);
                    }
                }
            }
            .warning {
                margin: 5px 0px 0px 10px;
            }
            .submenu-ul {
                overflow: hidden;
                max-height: 0;
                transition: max-height .35s;
                padding-left: 30px;
                display:none;
                span { font-size: 14px; }
                li {
                    margin-top: 14px;
                }
                &.open {
                    display: inherit;
                }
            }
            &.opendesplegable {
                .submenu-ul {
                    overflow: hidden;
                    max-height: 800px;
                    height: auto;
                    transition: max-height .35s;
                }
            }
        }
    }
}
