.header-login-container {
    display: flex;
    justify-content: center;
    padding: 19px 0px;
    background-image: linear-gradient(to right, #282828 0%, #282828 100%);
    border-bottom: 2px solid #fc531d;
    .logo {
        cursor: pointer;
    }
}

.header-container {
    border-bottom: #fc531d 2px solid;
    position: fixed;
    width: 100%;
    display: flex;
    padding: 19px 20px;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to right, #282828 0%, #282828 100%);
    z-index: 100;
    .logo {
        cursor: pointer;
        @media (max-width: 400px){
            width: 180px;
        }
    }
    .menu-movil, .user-movil, .close-menu{
        display: none;
        cursor: pointer;
        @media (max-width: 900px){
            display: initial;
        }
    }
    .bottons-content {
        span {
            margin-right: 20px;
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
            color: #fbfbfb;
            cursor: pointer;
            &.noClick { cursor: initial; }
            svg { margin-right: 5px; }
        }
        @media (max-width: 900px){
            position: absolute;
            z-index: 100;
            right: -100%;
            flex-direction: column-reverse;
            top: 86px;
            padding: 20px;
            display: flex;
            background-color: #192432;
            transition: all 500ms ease;
            &.active { right: 0 }
            span {
                color: #a2a9b0;
                text-decoration: none;
                display: flex;
                align-items: center;
                cursor: pointer;
                &:hover { color: #fff; }
                &:hover svg{ path { fill: #fff; } }
                &.active {
                    color: #fff;
                    svg{ path { fill: #fff; } }
                }
            }
            span:nth-child(2) {
                margin-bottom: 20px
            }
        }
    }

    @media (max-width: 900px){
        .admin-lang-selector{
            margin-bottom: 8px;
            span{
                margin-bottom: 0px !important;
            }
        }
        span#logout{
            margin-top: 8px;
        }
    }
}
