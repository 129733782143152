@import "../../../assets/styles/colors";

.white_brand{
    section.main {
        position: relative;
        background: #2b2b2b;
        background: -moz-linear-gradient(to right, #1f1f1f 0%, #2b2b2b 100%);
        background: -webkit-linear-gradient(to right, #1f1f1f 0%, #2b2b2b 100%);
        background: linear-gradient(to right, #1f1f1f 0%, #2b2b2b 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f1f1f', endColorstr='#2b2b2b',GradientType=1 );
        padding-top: 0;
        padding-bottom: 0;
        /* min-height: 100vh; */
    }
    h2{
        text-align: center;
        font-weight: bold;
    }
    .header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 4;
        padding: 40px 0;
    }
    section.main .main-content {
        padding-top: 0px;
    }
    .hamburguer {

    }
    .hamburger:hover{
        cursor: pointer;
    }
    .hamburger .line{
        width: 24px;
        height: 3px;
        background-color: #ecf0f1;
        display: block;
        margin: 5px auto;
        border-radius: 5px;
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    #hamburger.is-active .line:nth-child(2){
        opacity: 0;
    }

    #hamburger.is-active .line:nth-child(1){
          -webkit-transform: translateY(8px) rotate(45deg);
          -ms-transform: translateY(8px) rotate(45deg);
          -o-transform: translateY(8px) rotate(45deg);
          transform: translateY(8px) rotate(45deg);
    }

    #hamburger.is-active .line:nth-child(3){
          -webkit-transform: translateY(-8px) rotate(-45deg);
          -ms-transform: translateY(-8px) rotate(-45deg);
          -o-transform: translateY(-8px) rotate(-45deg);
          transform: translateY(-8px) rotate(-45deg);
    }

    .menu-content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.8);
    }
    /* .main-content {
        text-align: center;
        display: flex;
        flex-flow: column;
        min-height: calc(100vh - 80px);
        justify-content: space-evenly;
    }
    .main-content .btn-wrapper {
        margin-bottom: 30px;
    } */
    h1 {
        font-family: 'Poppins', sans-serif;
        font-size: 43px;
        font-weight: bold;
        margin-bottom: 40px;
        line-height: 1.5em;
    }
    .btn-default {
        background: $orange;
        color: $white;
        padding: 10px 45px;
    }
    @media (max-width: 991px) {
        h1 {
            font-size: 33px;
        }
        h1 {
            font-size: 26px;
        }
        .btn {
            font-size: 14px;
        }
    }
    @media (max-width: 767px) {
        .header {
            padding: 10px 0;
        }
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 21px;
        }
        .btn {
            font-size: 12px;
        }
    }
    @media (max-width: 575px) {
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 20px;
        }
        .nav-wrapper .btn-transparent {
            font-size: 12px;
            padding:  7px 25px;
        }
    }

    .landing-sample {
        padding:33px 94px 90px;
        border-radius: 4px;
        box-shadow: 0 -20px 60px 0 rgba(55, 79, 189, 0.38);
        background-color: #ffffff;

        transform: translateY(180px);
    }
    .landing-sample .content-wrapper {

    }
    .landing-sample .content-wrapper .sample-header {
        margin-bottom: 25px;

    }
    .landing-sample .content-wrapper .sample-header .sample-logo{
        position: relative;
        display: inline-block;
        float: left;
        width: 200px;
        height: 35px;
        background: #eeecf4;
    }
    .landing-sample .content-wrapper .sample-header .sample-menu {
        position: relative;
        display: inline-block;
        float: right;
        width: 35px;
        height: 35px;
        background: #eeecf4;
    }
    .landing-sample .content-wrapper .sample-header .sample-menu:after,
    .landing-sample .content-wrapper .sample-header .sample-logo:after {
        content: '';
        display: block;
        width: 100px;
        height: 100px;
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
    }
    .landing-sample .content-wrapper .sample-header .sample-logo:after {
        background: url('../../../assets/img/whitelabel/logo-pointer.svg') no-repeat center center / contain transparent;
    }
    .landing-sample .content-wrapper .sample-header .sample-menu:after {
        right: 0;
        background: url('../../../assets/img/whitelabel/menu-pointer.svg') no-repeat center center / contain transparent;
    }

    .landing-sample .content-wrapper .laurel {
        background: url('../../../assets/img/whitelabel/i-laurel.svg') no-repeat center center / contain #fff;
        padding: 0 15%;
    }

    @media (max-width: 991px) {
        .landing-sample {
            padding: 30px;
        }
        .landing-sample .content-wrapper .sample-header .sample-menu:after,
        .landing-sample .content-wrapper .sample-header .sample-logo:after {
            width: 80px;
            height: 80px;
        }
    }

    @media (max-width: 767px) {
        .landing-sample {
            padding: 15px;
            transform: translateY(140px);
        }
    }

    @media (max-width: 575px) {
        .landing-sample .content-wrapper .sample-header .sample-menu:after,
        .landing-sample .content-wrapper .sample-header .sample-logo:after {
            width: 40px;
            height: 40px;
        }
        .landing-sample .content-wrapper .sample-header {
            margin-bottom: 50px;
        }
    }

    .control {
        padding-top: 300px;
        padding-bottom: 5rem;
    }

    .control h2 {
        text-align: left;
        /*font-size: 47px;*/
        /* padding-right: 30%; */
    }

    .control p {
        color: #838383;
        /*font-size: 18px;*/
        text-align: justify;
    }

    .control .control-icon {
        width: 450px;
    }

    .control a {
        /*font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: bold;
        color: var(--main-color);
        text-decoration: underline;
        margin-top: 20px;*/
    }

    .separate-shape svg {
        -webkit-filter: drop-shadow(0px 7px 4px rgba(0,0,0,0.5));
        filter: drop-shadow(0px 7px 4px rgba(0,0,0,0.5));

        position: relative;
        top: -1px;
    }

    .description {
        padding: 50px 0;
        background: #fff6db;
        margin-bottom:50px;
    }

    .description .container {
        position: relative;
        z-index: 1;
        /* background: url('../img/tool-icon-100.svg') no-repeat center center / contain; */
    }

    .description .container:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: url('../../../assets/img/whitelabel/tool-icon-100.svg') no-repeat center center / contain;

        opacity: 0.6;
    }

    .description h3 {
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        text-align: center;
        color: #fc531d;

        margin-bottom: 80px;
        padding: 20px 20% 0;
    }

    .description .boxes-wrapper {
        margin-bottom: 80px;
    }

    .description .boxes-wrapper .box {
        /* height: 2em;
        display: inline-block;
        float: left;
        width: calc(33% - 17px);
        margin-right: 30px;*/


        background: #fff;
        color: $white;
        position: relative;

    }

    .description .boxes-wrapper .box:last-child {
        margin-right: 0;
    }

    .description .boxes-wrapper .box .box-order {
        background-color: $orange;
        float: left;
        display: inline-block;
        width: 70px;
        height: 70px;

        font-size: 36px;
        font-weight: bold;

        text-align: center;
        line-height: 70px;
    }

    .description .boxes-wrapper .box .box-content {
        display: inline-block;
        width: calc(100% - 70px);
        height: 70px;
        padding-left: 30px;

        font-size: 18px;
        line-height: 25px;
        padding: 10px 30px;
        color: $orange;
        b{
            color: #781f03;
        }
    }

    .description .btn-default {
        margin-bottom: 20px;
    }

    @media (max-width: 991px) {
        .landing-sample .boxes-wrapper .box {
            width: calc(33% - 28px);
            margin: 0 20px;
        }

        .control h2 {
            font-size: 28px;
        }

        .control p,
        .control a {
            font-size: 14px;
        }

        .description .boxes-wrapper .box .box-order {
            font-size: 22px;
            height: 50px;
            width: 50px;
            line-height: 50px;
        }
        .description .boxes-wrapper .box .box-content {
            font-size: 13px;
            height: 50px;
            line-height: 18px;
            padding: 6px 0 6px 15px;
        }
    }
    @media (max-width: 767px) {
        .landing-sample {
            padding: 33px;
            transform: translateY(154px);
        }
        .landing-sample .boxes-wrapper {
            padding: 30px 0;
        }
        .landing-sample .boxes-wrapper .box {
            width: calc(33% - 10px);
            margin: 0 5px;
            height: 85px;
        }
        .landing-sample .btn-default {
            padding: 15px 40px;
        }

        .control {
            padding-top: 180px;
            padding-bottom: 60px;
        }

        .control h2 {
            font-size: 20px;
            padding: 0;
            margin-bottom: 40px;
            /* text-align: center; */
        }

        .control p,
        .control a {
            font-size: 12px;
        }

        .description h3 {
            font-size: 16px;
            padding: 0 10%;
        }

        .description .boxes-wrapper .box {
            margin: 10px auto;
            max-width: 240px;
        }

        .description .boxes-wrapper .box:last-child {
            margin-right: auto;
        }

        .description .boxes-wrapper .box .box-order {
            font-size: 22px;
            height: 50px;
            width: 50px;
            line-height: 50px;
        }
        .description .boxes-wrapper .box .box-content {
            font-size: 13px;
            height: 50px;
            line-height: 18px;
            padding: 6px 0 6px 15px;
        }
    }
    @media (max-width: 575px) {
        .landing-sample {
            padding: 20px;
        }
        .landing-sample .content-wrapper .laurel {
            padding: 0 8% !important;
        }
        .landing-sample .content-wrapper .sample-header .sample-logo {
            width: 140px;
        }
        .main .btn-wrapper {
            padding: 0 0;
        }
        .control .control-icon {
            width: 200px;
            margin: 0 auto 40px;
            display: block;
        }
        .control h2 {
            text-align: center;
        }
    }

}
