@import '~react-toastify/dist/ReactToastify.css';
html, body {
    background-color: #282828;
    width: 100%;
    height: auto;

}

.Layout-container,.layout-content, .layout-content .content, #root {
    width: 100%;
    height: auto;
}

.content {
    position: relative;
    overflow: auto;
    background: #fff;
}
.Layout-container {
    position: relative;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
body {
    margin: 0;
    font-family: Karla !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.5px;
    text-align: justify;
    .layout-content {
        /*position: relative;*/
        align-items: stretch;
        width: 100%;
        display: flex;
        /*flex: 1;*/
        .sidebar-container {
            @media (max-width: 900px){  display: none;}
        }
        .hidden-sidebar {
            /*min-width: 230px;*/
            @media (max-width: 900px){  display: none;}
        }
        .hidden-footer {
            @media (max-width: 900px){ display: none;}
        }
        .sidebar-container-movil {
            width: 100%;
            height: 100%;
            left: -100%;
            z-index: 100;
            position: absolute;
            overflow-y: scroll;
            // box-shadow: 0 0 20px 0 #000;
            transition: all 500ms ease;
            background-color: #192432;
            &.active {
                left: 0;
            }
        }
        .content {
            padding: 20px;
            .srv-validation-message { color: #ff043a; }
            label {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.5px;
                text-align: justify;
                color: #4b5056;
            }
            input, textarea {
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.5px;
                color: #4b5056;
            }
        }
        .notif {
            padding: 10px 20px !important;
            min-height: inherit !important;
            .Toastify__toast-body {
                span {
                    display: flex;
                    align-items: center;
                    svg {
                        margin-right: 11px;
                    }
                }
            }
        }
        .succes-color {
            border-radius: 3px;
        }
        .error-color {
            border-radius: 3px;
            background-color: #ff043a;
         }
    }

}
