 .infoDetall-content {
    position: absolute;
    background-color: rgba(25, 36, 50, 0.7);
    width: 100%;
    height: unset;
    top: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    .card {
        width: 600px;
        height: fit-content;
        margin-top: 93px;
        margin-bottom: 0;
        border: solid 1px #c8d1db;
        .card-body { padding: 0; }
        .card-header {
            height: 39px;
            background-color: #f7f7f7;
            @media (max-width: 350px){
                h1 { font-size: 14px; }
            }
        }
        .detall {
            padding: 20px;
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                h1 {
                    font-size: 15px;
                    margin: 0 20px 0px 0px;
                    width: 50%;
                    text-align: right;
                    font-weight: bold;
                }
                p {
                    width: 50%;
                    margin: 0;
                    font-size: 15px;
                }
                @media (max-width: 450px){  
                    h1, p { font-size: 13px; }
                }
            }
        }
    }
}