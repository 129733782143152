.afiliado-content {
    width: 1300px;
    padding: 20px;
    display: flex;
    .input-checkbox { display: block; }
    textarea { resize: none; height: 80px !important; }
    label {
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -.5px;
        text-align: justify;
        color: #4b5056;
    }
    input {
        font-size: 14px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -.5px;
        color: #4b5056;
    }
    .error-password { color: #ff043a }
    .left {
        margin-right: 10px;
        .card { margin: 0; }
    }
    .right { margin-left: 10px; }
    @media (max-width: 900px){
       flex-direction: column;
    }
    .right, .left {
        width: 50%;
        .button-content { text-align: center;}
        .robot { display: flex; margin: 0; }
        @media (max-width: 900px){
           margin: 0;
           width: 100%;
         }
     }
     .right {
        .card { margin-bottom: 20px; }
     }
     .recaptcha-advice{
         color: #a2a2a2;
         font-size:12px;
     }
}
