.referidos-content {
    width: 100%;
    .card { margin-bottom: 20px; }
    .filter-content {
        width: 100%;
        display: flex;
        align-items: center;
        .form-group {
            // margin: 0
            &.select {
                width: 200px;
                margin-right: 20px;
            }
            label {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.5px;
                text-align: justify;
                color: #4b5056;
            }
            input{
                border: solid 1px #4b5056;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.5px;
                color: #4b5056;
                &::placeholder {
                    color: #4b5056;
                }
            }
            button { margin-top: 28px; }
            @media (max-width: 540px){
                &.select {
                    width: 100%;
                    margin-right: 0;
                }
            }
        }
        @media (max-width: 540px){ flex-direction: column; }
    }
    .table {margin: 0;}
    .table-content {
        width: 100%;
        height: auto;
        overflow: auto;
        .table {
            text-align: center;
            font-size: 14px;
            margin: 0;
            th,.total {
                color: #4b5056;
                background-color: #ececec;
                border: solid 1px #c8d1db;
            }
            .total {
                font-weight: bold;
            }
            td {
                color: #4b5056;
                border: solid 1px #c8d1db;
            }
            .par{
                background-color:#ffffff;
            }
            .impar{
                background-color: #fcfcfc;
            }
            th, td {
                span { display: block; }
            }
            .empty {
                td { height: 39px; }
            }
            th { border-top: none; }
        }
    }
    .referidos-cont-button {
        padding: 0px 20px;
        .text-content {
            p, a {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.5px;
                color: #4b5056;
                margin: 0;
            }
            a { font-weight: bold; }
        }
    }
    .referidos-cont-top {
        padding: 0px 20px;
        p {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.5px;
            text-align: justify;
            color: #4b5056;
            margin-bottom: 0;
        }
        a {
            display: inline-block;
            margin-bottom: 1rem;
         }
    }
}
