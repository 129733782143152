.ha-brokers {
    .calendar {
        position: relative;
        .form-control {
            input  {
                width: 100%;
                border: none;
                outline: none;
            }
        }
        .icon-calendar {
            cursor: pointer;
            position: absolute;
            right: 11px;
            top: 40px;
            z-index: 10;
        }
    }
}