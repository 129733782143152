
.tarifas-content {
    width: 100%;
    .card {
        .card-body { padding: 0; }
    }
    .table-content {
        overflow: auto;
        width: 100%;
        .table {
            text-align: center;
            font-size: 14px;
            margin: 0;
            th {
                color: #4b5056;
                background-color: #ececec;
                border: solid 1px #c8d1db;
                width: 50%;
                vertical-align: inherit;
            }
            td {
                color: #4b5056;
                border: solid 1px #c8d1db;
            }
            .par{
                background-color:#ffffff;
            }
            .impar{
                background-color: #fcfcfc;
            }
            th, td {
                padding: 8px;
                span { display: block; }
            }
            th { border-top: none; }
            &.sp th {border: solid 1px #c8d1db;}
        }
    }
    .text-content {
        p {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: center;
            letter-spacing: -0.5px;
            color: #4b5056;
            margin: 0;
        }
    }
}
