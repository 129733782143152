.landing-content {
    width: 100%;
    .card { margin-bottom: 20px; }
    .text-content {
        p {
            font-size: 14px;
            color: #4b5056;
        }
    }
    .form-content {
        display: flex;
        justify-content: center;
        form { width: 550px }
    }
    .spcial-card {
        .card { margin: 0; }
        .card .card-body {
            padding: 0;
            .link-content {
                padding: 20px;
                max-height: 400px;
                overflow: scroll;
            }
            .view-content {
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                height: 39px;
                display: flex;
                align-items: center;
                background-color: #f4f9ff;
                span {
                    height: 100%;
                    width: 144px;
                    background-color: #f7f7f7;
                    border-right: 1px solid rgba(0, 0, 0, 0.125);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    cursor: pointer;
                    &:hover, &.active {
                        background-color: #fcfdff;
                    }
                    svg { margin-right: 6px; }
                    @media (max-width: 500px){ width: 50%; }
                }
                @media (max-width: 500px){
                    span:nth-child(2){ border: none }
                }
            }
        }
    }
}
