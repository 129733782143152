@import "../../../assets/styles/colors";

.public-layout{
    footer {
        background: #232323;
        font-size: 13px;
        padding: 50px 0;
        color:#fff;
        border-top: $orange 2px solid;
    }

    footer .menufooter a {
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.6px;
        color: #fff;
        margin: 1rem 0;
        transition: all .3s ease;
    }

    footer .menufooter a:hover {
        color: $orange;
        text-decoration: none;
    }

    @media (max-width: 991px) {
        footer{
            padding-bottom:7rem !important;
        }
    }
}
