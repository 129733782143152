.card-container {
    width: 580px;
    margin-top: 25px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.5px;
    text-align: justify;
    color: #4b5056;
    .button-content {
        margin-top: 50px !important;
        text-align: center;
    }
    p {
        margin: 0;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: -0.5px;
        text-align: justify;
        color: #4b5056;
    }
}