@import "../../../assets/styles/colors";

.rates{
    section {
        padding: 50px 0;
    }

    section.main {
        position: relative;
        background:url("../../../assets/img/rates/taxes_bg.jpg") no-repeat center left / cover #000;
        //background-image: url(/images/image.jpeg);
        padding-bottom: 80px;
        padding-top: 0;
    }

    @media (max-width: 991px) {
        section.main {
            background:url("../../../assets/img/rates/taxes_bg.jpg") no-repeat center center / cover #000;
        }
    }


    section.main .main-content {
        padding-top: 180px;
    }
    .main-content {
        text-align: center;
        display: flex;
        flex-flow: column;
        /* min-height: calc(100vh - 80px); */
        justify-content: space-evenly;
        h1{
            color: $white;
            margin-bottom: 40px;
        }
        .btn-wrapper {
            margin-bottom: 30px;
        }
    }

    .btn:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    .btn-default, a.btn-default {
        background: $orange;
        color: $white;
        padding: 19px 70px;
    }
    .btn-home {
        font-size: 14px;
        background: $orange;
        color: #402961;
        padding: 19px 70px !important;
        transition: all .3s ease;
    }

    /**********************************/

    .subtitle {
        font-size: 16px;
        font-weight: normal;
        color: $orange;
        text-align: center;
    }

    /* INFO SECTION */

    .info {
        background-color: #282828;
        color: #fff;
        font-weight: 300;
        font-size: 16px;

        position: relative;
        /* margin: 30px 0 0; */
        padding: 0 15px;
    }

    .info p {
        margin-bottom: 20px;
    }

    .info .info1, .info .info2, .info .info3 {
        padding: 35px 0 35px 0;
    }

    .info .info1 {
        background-color: #282828;
    }

    .info .info2 {
        background-color: #303030;
    }

    .info .info3 {
        background-color: #282828;
    }

    .info .example-logo-wrapper img {
        margin-bottom: 30px;
    }

    .info .info-overlay {
        position: absolute;
        height: calc(100% + 60px);
        top: -30px;
        right: 0;
        width: 40%;

        background: url('../../../assets/img/info_bg.jpg') no-repeat center center / cover;

        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }

    .info .info-overlay .info-overlay-contain {
        width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .info .info-overlay .info-overlay-contain .icon {
        padding: 15px;
        max-width: 120px;
    }

    .info .info-overlay:before,
    .info .info-overlay:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;

        border-style: solid;
        left: -30px;
    }

    .info .info-overlay:before {
        top: 0;
        border-width: 0 0 30px 30px;
        border-color: transparent transparent #7b7b7b transparent;
    }
    .info .info-overlay:after {
        bottom: 0;
        border-width: 0 30px 30px 0;
        border-color: transparent #7b7b7b transparent transparent;

    }

    @media (max-width: 575px) {
        .info .info-overlay {
            position: relative;
            top: 0;
            left: -15px;
            width: calc(100% + 30px);
        }
        .info .info-overlay:before, .info .info-overlay:after {
            display: none;
        }
        .info .info-overlay .info-overlay-contain .icon {
            width: 40%;
        }
    }
    /* VALUES SECTION*/
    section.values{
        /* padding: 120px 0 120px; */
        h2{
            text-align:center;
        }

        .icon {
            display: inline-block;
            /* float: left; */
        }

        .thumbs-wrapper {
            /*padding: 64px 0;*/
        }

        .thumbs-wrapper .thumb {
            padding-top: 30px;
            padding-bottom: 30px;
            display: flex;
            align-items: center;
        }

        .thumbs-wrapper .text {
            margin-left: 20px;
        }

        /* .thumbs-wrapper h4 {
            padding-left: 30px;
            font-size: 16px;
            line-height: 1.3em;
            text-align: justify;
        } */

        .thumbs-wrapper .icon {
            flex: 0 0 auto;
            /* margin-left: -30px; */
            /* width: 70px; */
        }

        .thumbs-wrapper .icon img {
            width: auto;
            height: 67px;
        }

        .thumbs-wrapper .thumb-title,
        .thumbs-wrapper .thumb-subtitle {
            color: #372454;
            /* padding-left: 15px; */
        }

        .thumbs-wrapper .thumb-title {
            display: block;
            font-weight: bold;
            font-size: 20px;
            padding-top: 0;
            padding-bottom: 12px;
            /* padding-left: 15px; */
        }

        .thumbs-wrapper .thumb-subtitle {
            font-size: 14px;
            margin-top: 10px;
        }

        @media (min-width: 768px) {
            /* .thumbs-wrapper .thumb:nth-child(odd) {
                padding-right: 70px;
            }
            .thumbs-wrapper .thumb:nth-child(even) {
                padding-left: 70px;
            } */
        }

        @media (max-width: 991px) {
            .thumbs-wrapper {

            }
            .thumbs-wrapper .icon img {
                height: 50px;
            }
            .thumbs-wrapper .thumb-title {
                /* width: calc(100% - 60px); */
                font-size: 14px;
            }

            .thumbs-wrapper .thumb-subtitle {
                font-size: 11px;
            }
        }

        @media (max-width: 567px) {
            .thumbs-wrapper {
                padding: 20px 0;
            }
            .thumbs-wrapper .thumb-title {
                font-size: 14px;
            }

            .thumbs-wrapper .thumb-subtitle {
                font-size: 10px;
            }
        }
    }


    /* SAMPLE SECTION*/
    .samples {
        background: url('../../../assets/img/sample_bg.jpg') no-repeat center center / cover #000;
        padding: 100px 0 100px;
        text-align: center;
        .container{
            padding-bottom: 3rem;
        }
        h2 {
            text-align: center;
            color: $white;
        }
    }

    .samples .subtitle {
        color: $orange;
    }

    .samples .boxes-wrapper {
        display: grid;
        justify-content: center;
        align-items: center;
        height: 100%;
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-gap: 30px 30px;
        grid-template-areas: ". . ." ". . .";

        padding-top: 50px;
    }

    .samples .boxes-wrapper .box {
        position: relative;
        border-radius: 40px;
        padding: 50px 30px;

        text-align: center;
    }

    .samples .boxes-wrapper .box:after {
        border-radius: 40px;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 0;

        mix-blend-mode: multiply;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0.72;
    }

    .samples .boxes-wrapper .box .box-content {
        z-index: 1;
        position: relative;
    }

    .samples .boxes-wrapper .box .btn {
        margin-top: 40px;


    }

    .samples .boxes-wrapper .box img {
        display: block;
        margin: 0 auto;
    }

    @media (max-width: 767px) {

        .samples .boxes-wrapper {
            grid-template-areas: ". .";
            grid-gap: 15px 15px;
        }
    }

    @media (max-width: 567px) {
        .samples {
            padding: 70px 0;
        }
        .samples .boxes-wrapper {
            padding-top: 30px;
        }
        .samples .boxes-wrapper .box {
            padding: 41px 22px;
        }
        .samples .boxes-wrapper .box .btn {
            margin-top: 20px;
        }
        /* .samples .boxes-wrapper {
            grid-template-areas: ".";
        } */
    }


    /* Tarifas */

    .pricing-boxes.pago_email {
        background-color: transparent;
        width: 100%;
    }

    .pricing-boxes {
        background-color: #f5f8ff;
        /*color: ;*/
    }

    .pricing-wrapper > div:first-child{
        margin-left: 0;
    }

    .pricing-wrapper > div:last-child{
        margin-right: 0;
    }

    .pricing {
        display: inline-block;
        float: left;
        margin: 0 10px;
        /*width: calc(33.333% - 14px);*/
        width: 100%;
        background-color: #fff;
        min-height: 400px;
        border-radius: 0 0 10px 10px;
        /*box-shadow: 0 2px 27px 0 rgba(0, 0, 0, 0.08);*/
    }

    .pricing-wrapper .pricing-title {
        /*border-radius: 10px;*/
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-image: linear-gradient(to left, #ffcb00, #ff3e00);
        /*border-radius: 10px 10px 0 0;*/
        padding: 20px 0;
    }
    .pricing-wrapper .pricing-title h3 {
        font-size: 24px;
        letter-spacing: 5px;
        padding: 26px 0 20px;
        margin: 0;
        color: #fff;
        text-align: center;
    }
    .pricing.pricing-climb h3 {
        /*background: url('../img/shape.svg') no-repeat center center / contain;*/
    }
    .pricing.pricing-pack h3 {
        /*background: url('../img/wall-clock.svg') no-repeat center center / contain;*/
    }
    .pricing.pricing-operator h3 {
        /*background: url('../img/chat-2.svg') no-repeat center center / contain;*/
    }
    .pricing ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-top: 2px;
    }

    .pricing ul li {
        list-style: none;
        margin: 0;
        display: block;
        clear: both;
        background-color: #e3e3e3;
        padding: 10px 30px;
        font-size: 14px;
    }

    .pricing ul li:nth-child(even) {
        background: #eeeeee;
    }


    .pricing .limit {
        float: left;
    }
    .pricing .price {
        float: right;
        font-weight: bold;
    }

    .disclaimer {
        margin-top: 40px;
        font-size: 12px;
    }

    @media (max-width: 991px) and (min-width: 576px) {
        .pricing-boxes .container {
            max-width: 100% !important;
        }
    }

    @media (max-width: 1199px) {
        .pricing-wrapper .pricing-title h3 {
            font-size: 2.5vw;
        }
        .pricing ul li {
            padding: 10px;
            font-size: 12px;
        }

    }

    @media (max-width: 699px) {
        .pricing-wrapper .pricing-title h3 {
            font-size: 20px;
        }
        .pricing {
            width: 100%;
            margin: 0 0 40px 0;
            max-width: 400px;
            display: block;
            margin-left: auto !important;
            margin-right: auto !important;
            float: none;
        }

    }
}
