@import "../../../assets/styles/colors";

.homepage{
    section {
        padding: 50px 0;
    }

    section.main {
        position: relative;
        background:url("../../../assets/img/home/home_bg.jpg") no-repeat center left / cover #000;
        padding-bottom: 80px;
        padding-top: 0;
    }


    section.main .main-content {
        padding-top: 180px;
    }
    .main-content {
        text-align: center;
        display: flex;
        flex-flow: column;
        /* min-height: calc(100vh - 80px); */
        justify-content: space-evenly;
        h1{
            color: $white;
            margin-bottom: 40px;
            font-weight: bold;
        }
        .btn-wrapper {
            margin-bottom: 30px;
        }
    }

    .btn:not(:disabled):not(.disabled) {
        cursor: pointer;
    }
    .btn-default, a.btn-default {
        background: $orange;
        color: $white;
        padding: 10px 50px;
        font-weight: bold;
    }
    .btn-home {
        font-size: 14px;
        font-weight: bold;
        background: $orange;
        color: #402961;
        padding: 19px 70px !important;
        transition: all .3s ease;
    }
    .main:after {
        content: '';
        display: block;
        position: absolute;
        height: 80px;
        width: 140px;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        background: url("../../../assets/img/drop-shape.svg") no-repeat center center / contain;
        //background-image: url("/images/drop-shape.svg");
        transition: all 0.3s ease-in-out;
    }

    /**********************************/
    .maxim {
        padding: 50px 120px;
        text-align: center;
        h2 {
            font-weight: bold;
        }
    }

    .maxim .subtitle {
        margin: 0;
    }

    .maxim .boxes-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 45px;

        position: relative;
        z-index: 0;
        .external-box{
            margin: 30px 17px;
            padding: 6px;
            border-radius: 31px;
            border: solid 3px #e6e6e6;
        }
    }

    .maxim .boxes-wrapper:before,
    .maxim .boxes-wrapper:after {
        display: block;
        content: '';
        position: absolute;
        height: calc(100% + 180px);
        width: 250px;
        top: -100px;
        z-index: -1;
    }

    .maxim .boxes-wrapper:before {
        left: 10%;
        background: url('../../../assets/img/laurel-right.svg') no-repeat center center / contain;

        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }

    .maxim .boxes-wrapper:after {
        right: 10%;
        background: url('../../../assets/img/laurel-right.svg') no-repeat center center / contain;
    }

    .maxim .boxes-wrapper .box {
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        padding: 16px 15px 18px;
        font-weight: bold;
        flex: 0 0 auto;
        border-radius: 26px;
        text-align: center;

        width: 160px;
        max-width: 160px;
        height: 160px;

        background-image: linear-gradient(to bottom, #565656 0%, #282828);
    }

    .maxim .boxes-wrapper .box .top {
        font-size: 64px;
        line-height: 0.88em;
        color: $secondary-orange;
        display: block;

        letter-spacing: 1px;
    }

    .maxim .boxes-wrapper .box .top.small {
        font-size: 24px;
        line-height: 1.1em;
    }

    .maxim .boxes-wrapper .box .top.smallest {
        font-size: 18px;
        line-height: 1.1em;
    }

    .maxim .boxes-wrapper .box .sub {
        color: $white;
        font-size: 23px;
    }

    @media (max-width: 991px) {
        .maxim {
            padding: 120px 40px;
        }
        .maxim p {
            padding: 0 15%;
            font-size: 14px;
        }
        .maxim .boxes-wrapper .box {
            max-width: 120px;
            height: 120px;
        }
        .maxim .boxes-wrapper .box .top {
            font-size: 32px;
        }
        .maxim .boxes-wrapper .box .top.small {
            font-size: 12px;
        }
        .maxim .boxes-wrapper .box .top.smallest {
            font-size: 12px;
        }
        .maxim .boxes-wrapper .box .sub {
            font-size: 12px;
        }
        .maxim .boxes-wrapper:before, .maxim .boxes-wrapper:after {
            height: calc(100% + 195px);
            width: 165px;
            top: -150px;
        }
        .maxim .boxes-wrapper:before {
            left: 5%;
        }
        .maxim .boxes-wrapper:after {
            right: 5%;
        }

    }

    @media (max-width: 575px) {
        .btn {
            padding: 8px 25px;
        }
        .maxim {
            padding: 70px 10px;
        }
        .maxim p {
            margin-bottom: 25px;
        }
        .maxim .boxes-wrapper {
            display: block;
            text-align: center;
        }
        .maxim .external-box{
            margin: 20px auto !important;
            max-width: 140px;
        }
        .maxim .boxes-wrapper .box {
            /* max-width: 90px;
            height: 90px; */

            width: 100%;
            /*max-width: 220px;*/
            border-radius: 30px;
            margin: auto auto;
        }
        .maxim .boxes-wrapper .box .top {
            font-size: 40px;
        }
        .maxim .boxes-wrapper .box .top.small {
            font-size: 16px;
        }
        .maxim .boxes-wrapper .box .sub {
            font-size: 16px;
        }
        .maxim .boxes-wrapper:before, .maxim .boxes-wrapper:after {
            top: -100px;
        }
        .maxim .boxes-wrapper:before, .maxim .boxes-wrapper:before {
            left: 0;
        }
        .maxim .boxes-wrapper:before, .maxim .boxes-wrapper:after {
            right: 0;
        }
    }

    .subtitle {
        font-size: 16px;
        font-weight: normal;
        color: $orange;
        text-align: center;
    }

    /* INFO SECTION */

    .info {
        background-color: #282828;
        color: #fff;
        font-weight: 300;
        font-size: 16px;

        position: relative;
        /* margin: 30px 0 0; */
        padding: 0 15px;
    }

    .info p {
        margin-bottom: 20px;
    }

    .info .info1, .info .info2, .info .info3 {
        padding: 35px 0 35px 0;
    }

    .info .info1 {
        background-color: #282828;
    }

    .info .info2 {
        background-color: #303030;
    }

    .info .info3 {
        background-color: #282828;
    }

    .info .example-logo-wrapper img {
        margin-bottom: 30px;
    }

    .info .info-overlay {
        position: absolute;
        /*height: calc(100% + 60px);*/
        height: calc(100% + 30px);
        /*top: -30px;*/
        top: 0px;
        right: 0;
        width: 40%;

        background: url('../../../assets/img/info_bg.jpg') no-repeat center center / cover;

        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }

    .info .info-overlay .info-overlay-contain {
        width: 100%;
        width: 100%;
        flex: 0 0 auto;
    }

    .info .info-overlay .info-overlay-contain .icon {
        padding: 15px;
        max-width: 120px;
    }

    //.info .info-overlay:before,
    .info .info-overlay:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;

        border-style: solid;
        left: -30px;
    }

    .info .info-overlay:before {
        /*top: 0;
        border-width: 0 0 30px 30px;
        border-color: transparent transparent #7b7b7b transparent;*/
    }
    .info .info-overlay:after {
        bottom: 0;
        border-width: 0 30px 30px 0;
        border-color: transparent #7b7b7b transparent transparent;

    }

    @media (max-width: 575px) {
        .info .info-overlay {
            position: relative;
            top: 0;
            left: -15px;
            width: calc(100% + 30px);
        }
        .info .info-overlay:before, .info .info-overlay:after {
            display: none;
        }
        .info .info-overlay .info-overlay-contain .icon {
            width: 40%;
        }
    }

    /* WORK WITH US SECTION */

    section.work-with{
        border-top:2px solid #d20085;
        border-bottom:2px solid #d20085;
        min-height: 35rem;
        padding:5rem 0;
        background:url("../../../assets/img/home/home_work_with_bg.jpg") no-repeat center left / cover #000;
        .container{
            max-width:80%;
        }
        .title{
            font-weight: bold;
            font-size: 24px;
            letter-spacing: -0.5px;
        }
        .subtitle{
            letter-spacing: -0.12px;
            font-weight: bold;
            font-size: 14px;
            color:#d20085;
            margin-bottom:25px;
        }
        .btn{
            background-color:#d20085;
        }
    }

    @media (max-width: 991px) {

        section.work-with{
            background:url("../../../assets/img/home/home_work_with_mobile_bg.jpg") no-repeat center center / cover #000;
        }
    }



    /* WORK WITH US SECTION */


    /* SAMPLE SECTION*/
    .samples {
        background: url('../../../assets/img/sample_bg.jpg') no-repeat center center / cover #000;
        padding: 100px 0 100px;
        text-align: center;
        .container{
            padding-bottom: 3rem;
        }
        h2 {
            text-align: center;
            color: $white;
        }
    }

    .samples .subtitle {
        color: $orange;
    }

    .samples .boxes-wrapper {
        display: grid;
        justify-content: center;
        align-items: center;
        height: 100%;
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-gap: 30px 30px;
        grid-template-areas: ". . ." ". . .";

        padding-top: 50px;
    }

    .samples .boxes-wrapper .box {
        position: relative;
        border-radius: 40px;
        padding: 50px 30px;

        text-align: center;
    }

    .samples .boxes-wrapper .box:after {
        border-radius: 40px;
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        z-index: 0;

        mix-blend-mode: multiply;
        background-color: rgba(0, 0, 0, 0.4);
        opacity: 0.72;
    }

    .samples .boxes-wrapper .box .box-content {
        z-index: 1;
        position: relative;
    }

    .samples .boxes-wrapper .box .btn {
        margin-top: 40px;


    }

    .samples .boxes-wrapper .box img {
        display: block;
        margin: 0 auto;
    }

    @media (max-width: 767px) {

        .samples .boxes-wrapper {
            grid-template-areas: ". .";
            grid-gap: 15px 15px;
        }
    }

    @media (max-width: 567px) {
        .samples {
            padding: 70px 0;
        }
        .samples .boxes-wrapper {
            padding-top: 30px;
        }
        .samples .boxes-wrapper .box {
            padding: 41px 22px;
        }
        .samples .boxes-wrapper .box .btn {
            margin-top: 20px;
        }
        .samples .boxes-wrapper {
            grid-template-areas: ".";
        }
    }

    @media (max-width: 991px) {
        .prizes-slider {
            max-width: 770px;
        }
        h1 {
            font-size: 33px;
        }
        h1 {
            font-size: 26px;
        }
        .btn {
            font-size: 14px;
        }
    }
    @media (max-width: 767px) {
        .header {
            padding: 10px 0;
        }
        .prizes-slider {
            max-width: 560px;
        }
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 21px;
        }
        .btn {
            font-size: 12px;
        }
    }
    @media (max-width: 575px) {
        .prizes-slider {
            /* max-width: 50%; */
            margin: 40px auto;
        }
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 20px;
        }
        .nav-wrapper .btn-transparent {
            font-size: 12px;
            padding:  7px 25px;
        }
    }
}
