.banners-content {
    width: 100%;
    .card { margin: 0; }
    .card .card-body {
        padding: 0;
        .view-content {
            border-bottom: solid 1px #c8d1db;
            height: 39px;
            display: flex;
            align-items: center;
            background-color: #f4f9ff;
            border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
            span {
                border-radius: calc(0.25rem - 1px) 0 0 0;
                height: 100%;
                width: 179px;
                background-color: #f7f7f7;
                border-right: solid 1px #c8d1db;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                cursor: pointer;
                &:hover, &.active {
                    background-color: #fcfdff;
                }
                svg { margin-right: 6px; }
                @media (max-width: 500px){ width: 50%; }
                @media (max-width: 350px){ font-size: 14px; }
            }
            @media (max-width: 500px){
                span:nth-child(2){ border: none }
            }
        }
        .banners-card-content,
        .especial-card-content {
            padding: 1.25rem;
            .banner-card,
            .especial-card {
                width: 100%;
                border: solid 1px #c8d1db;
                border-bottom: none;
                &:last-child {
                    &.active { border-bottom: solid 1px #c8d1db; }
                }
                .banner-card-header {
                    height: 39px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid;
                    width: 100%;
                    padding: 0.75rem 1.25rem;
                    margin-bottom: 0;
                    background-color: #f7f7f7;
                    border-bottom: solid 1px #c8d1db;
                    .closedesple {
                        position: absolute;
                        z-index: 10;
                        top: 8px;
                        right: 16px;
                        transform: rotate(90deg);
                    }
                    position: relative;

                    cursor: pointer;
                    h1 {
                        font-size: 16px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: -0.5px;
                        text-align: start;
                        color: #4b5056;
                        margin: 0px 0px 0px 10px;
                    }
                }
                .banner-card-body {
                    padding: 1.25rem;
                    width: 100%;
                    display: none;
                    &.active { display: block; }
                    .banner {
                        display: grid;
                        grid-template-columns: auto auto auto auto;
                        grid-gap: 20px;
                        transition: max-height 0.2s ease-out;
                        @media (max-width: 1050px){
                            grid-template-columns: auto auto auto;
                        }
                        @media (max-width: 600px){
                            grid-template-columns: auto auto;
                        }
                    }
                    .banner-img-content {
                        // max-height: 400px;
                        position: relative;
                        height: fit-content;
                        img {
                            width: 100%;
                            height: auto;
                        }
                        p {
                            margin: 0;
                        }
                        .button--download, .button--download-small {
                            opacity: 0;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            display: flex;
                            justify-content: center;
                            width: 140px;
                            height: 40px;
                            justify-content: flex-start;
                            align-items: center;
                            padding: 0;
                            background-color: rgba(0, 0, 0, 0.5);
                            border: solid 2px #fbfbfb;
                            border-radius: 31px;
                            font-size: 16px;
                            svg {
                                width: 24px;
                                margin-right: 11px;
                                margin-left: 7px;
                            }
                        }
                        .button--download-small {
                            width: 66px;
                            height: 66px;
                            border-radius: 35px;
                            justify-content: center;
                            min-width: fit-content;
                            svg {
                                width: 36px;
                                height: 36px;
                                margin: 0;
                            }
                        }
                        &:hover {
                            &::before {
                                content: "";
                                width: 100%;
                                height: 100%;
                                background-color: rgba(0, 0, 0, 0.4);
                                position: absolute;
                              }
                            .button--download, .button--download-small {
                                opacity: 1;
                                transition: all 1s;
                            }
                        }
                    }
                    .banner-img-content:last-child {
                        margin-right: 0;
                    }
                }
                &.active {
                    .closedesple{
                        transition: transform .35s;
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .especial-card-content {
            .banner-card-body {
                padding: 1.25rem 1.25rem 0 1.25rem !important;
            }
            .banner-img-content {
                position: relative;
                margin-bottom: 1.25rem;
                img {width: 100%;}
                .download {
                    opacity:0;
                    cursor: pointer;
                    position: absolute;
                    right: 5px;
                    padding: 6px 10px;
                    top: 5px;
                    border-radius: 3px;
                    background-color: #fc531d;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: -0.5px;
                    text-align: center;
                    color: #fff;
                }
                &:hover {
                    &::before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.4);
                        position: absolute;
                      }
                    .download {
                        opacity: 1;
                        transition: all 1s;
                    }
                }
            }
        }
    }
}
