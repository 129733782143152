#title { display: none;}
.avis-content {
    position: absolute;
    background-color: rgba(25, 36, 50, 0.7);
    width: 100%;
    height: unset;
    top: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    &.hidden {
        display: none;
    }
    iframe {
        width: 100%;
        height: 350px;
    }
    .card {
        width:90%;
        max-width: 1024px;
        height: fit-content;
        margin-top: 93px;
        margin-bottom: 0;
        border: solid 1px #c8d1db;
        position:fixed;
        .card-body { padding: 0; }
        .card-header {
            height: 39px;
            background-color: #f7f7f7;
            @media (max-width: 350px){
                h1 { font-size: 14px; }
            }
        }
        .text-content {
            padding: 1.25rem;
            &.contract { padding: 0; }
        }
        .text-notice {
            h1, p {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.5px;
                text-align: justify;
                color: #4b5056;
                margin-bottom: 20px;
            }
            p:nth-child(3) { margin: 0; }
            h1 {
                font-size: 16px;
                font-weight: bold;
            }
        }
        .button-content {
            margin-bottom: 20px;
            margin-top: 20px;
            button{ width: 200px;}
            @media (max-width: 360px){
               display: block;
               button:nth-child(2) {
                 margin: 20px 0px 0px 0px;
               }
            }
        }
    }
}
