body {
    margin: 0;
    .LoginLayout-container {
        display: flex;
        min-height: 100vh;
        flex-direction: column; 
        position: relative;
    }
    .content {
        height: -webkit-fill-available;
        display: flex;
        justify-content: center;
        flex: 1;
        .srv-validation-message { color: #ff043a; }
        .error-input {
            border: 1px solid #ff043a;
        }
    }
}