@import "../../../assets/styles/colors";

.contact{
    font-family: Karla;
    section.main {
        /*position: relative;
        background: #2b2b2b;
        background: -moz-linear-gradient(to right, #1f1f1f 0%, #2b2b2b 100%);
        background: -webkit-linear-gradient(to right, #1f1f1f 0%, #2b2b2b 100%);
        background: linear-gradient(to right, #1f1f1f 0%, #2b2b2b 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f1f1f', endColorstr='#2b2b2b',GradientType=1 );
        padding-top: 0;
        padding-bottom: 0;*/
    }

    h2{
        text-align: center;
        font-weight: bold;
    }
    .subtitle{
        color:#838383;
        text-align: center;
    }
    .header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 4;
        padding: 40px 0;
    }
    section.main .main-content {
        height:20rem;
        position: relative;
        background: #2b2b2b;
        background: -moz-linear-gradient(to right, #1f1f1f 0%, #2b2b2b 100%);
        background: -webkit-linear-gradient(to right, #1f1f1f 0%, #2b2b2b 100%);
        background: linear-gradient(to right, #1f1f1f 0%, #2b2b2b 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f1f1f', endColorstr='#2b2b2b',GradientType=1 );
        padding-top: 0;
        padding-bottom: 0;

    }

    .menu-content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.8);
    }

    label{
        color: #838383;
    }
    .form-control-sm,
    .input-group-sm>.form-control,
    .input-group-sm>.input-group-append>.btn,
    .input-group-sm>.input-group-append>.input-group-text,
    .input-group-sm>.input-group-prepend>.btn,
    .input-group-sm>.input-group-prepend>.input-group-text {
        border-radius: 1.5rem;
        line-height: 2.5;
    }

    .required label:after{
        content: "*";
        margin-left: 0.5em;
    }


    /* .main-content {
        text-align: center;
        display: flex;
        flex-flow: column;
        min-height: calc(100vh - 80px);
        justify-content: space-evenly;
    }
    .main-content .btn-wrapper {
        margin-bottom: 30px;
    } */
    h1 {
        font-family: 'Poppins', sans-serif;
        font-size: 43px;
        font-weight: bold;
        margin-bottom: 40px;
        line-height: 1.5em;
    }
    .btn-default {
        background: $orange;
        color: $white;
        padding: 10px 50px;
    }
    @media (max-width: 991px) {
        h1 {
            font-size: 33px;
        }
        h1 {
            font-size: 26px;
        }
        .btn {
            font-size: 14px;
        }
    }
    @media (max-width: 767px) {
        .header {
            padding: 10px 0;
        }
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 21px;
        }
        .btn {
            font-size: 12px;
        }
    }
    @media (max-width: 575px) {
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 20px;
        }
        .nav-wrapper .btn-transparent {
            font-size: 12px;
            padding:  7px 25px;
        }
    }

    .landing-sample {
        padding:33px 94px 90px;
        border-radius: 4px;
        box-shadow: 0 -20px 60px 0 rgba(55, 79, 189, 0.38);
        background-color: #ffffff;

        transform: translateY(-130px);
    }
    .landing-sample .content-wrapper {
        /*padding:0 7rem;*/
    }
    .landing-sample .content-wrapper .sample-header {
        margin-bottom: 25px;

    }
    .landing-sample .content-wrapper .sample-header .sample-logo{
        position: relative;
        display: inline-block;
        float: left;
        width: 200px;
        height: 35px;
        background: #eeecf4;
    }
    .landing-sample .content-wrapper .sample-header .sample-menu {
        position: relative;
        display: inline-block;
        float: right;
        width: 35px;
        height: 35px;
        background: #eeecf4;
    }
    .landing-sample .content-wrapper .sample-header .sample-menu:after,
    .landing-sample .content-wrapper .sample-header .sample-logo:after {
        content: '';
        display: block;
        width: 100px;
        height: 100px;
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
    }
    .landing-sample .content-wrapper .sample-header .sample-logo:after {
        /*background: url('/img/logo-pointer.svg') no-repeat center center / contain transparent;*/
    }
    .landing-sample .content-wrapper .sample-header .sample-menu:after {
        right: 0;
        /*background: url('../img/menu-pointer.svg') no-repeat center center / contain transparent;*/
    }

    #alert{
        display: none;
        text-align: center;
    }

    @media (max-width: 991px) {
        .landing-sample {
            padding: 30px;
        }
        .landing-sample .content-wrapper .sample-header .sample-menu:after,
        .landing-sample .content-wrapper .sample-header .sample-logo:after {
            width: 80px;
            height: 80px;
        }
    }

    @media (max-width: 767px) {
        .landing-sample {
            padding: 15px;
            transform: translateY(-140px);
        }
    }

    @media (max-width: 575px) {
        .landing-sample .content-wrapper .sample-header .sample-menu:after,
        .landing-sample .content-wrapper .sample-header .sample-logo:after {
            width: 40px;
            height: 40px;
        }
        .landing-sample .content-wrapper .sample-header {
            margin-bottom: 50px;
        }
    }

    .recaptcha-advice{
        color: #a2a2a2;
        font-size:12px;
    }
}
