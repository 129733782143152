.ha-aff-billing{
    .table{
        tr.payed td{
            color: #842029;
            background-color: #f8d7da;
            border-color: #f5c2c7;
        }
        tr.pending td{
            color: #0f5132;
            background-color: #d1e7dd;
            border-color: #badbcc;
        }
        tr.under_min td{
            color: #664d03;
            background-color: #fff3cd;
            border-color: #ffecb5;
        }
        tr.received td{
            color: #055160;
            background-color: #cff4fc;
            border-color: #b6effb;
        }
    }
}