.notice-component-content {
    position: absolute;
    background-color: rgba(25, 36, 50, 0.7);
    width: 100%;
    height: unset;
    top: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    .card {
        width: 600px;
        height: fit-content;
        margin-top: 93px;
        margin-bottom: 0;
        border: solid 1px #c8d1db;
        .card-body { padding: 0; }
        .card-header {
            height: 39px;
            background-color: #f7f7f7;
            @media (max-width: 350px){
                h1 { font-size: 14px; }
            }
        }
        .text-content {
            padding: 1.25rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            h1, p {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.5px;
                text-align: justify;
                color: #4b5056;
                margin: 0;
            }
            h1 {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 20px;
            }
        }
        .button-content {
            margin-bottom: 20px;
            margin-top: 20px;
            button{ width: 200px;}
        }
    }
}