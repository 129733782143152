@import "../../assets/styles/colors";

.button {
    font-family: Karla;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    text-align: center;
    border-radius: 3px;
    color: #fff;
    background-color: $orange;
    text-decoration: none;
    border: none;
    padding: 12px 15px;
    display: inline-block;
    cursor: pointer;
    max-width: 100%;
    height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 3px;

    &.button--violet{
        background-color: #8d53c8;
    }

    &.button--blue{
        background-color: #0592d8;
    }

    &.button--softblue{
        background-color: #55929e;
    }

    &.button--initial{
      background-color: #e2e9f2;
      color: #4b5056;
      border-radius: 0;
    }
    &.button--superBlue {
      background-color: #0163db;
    }
    &.button--red {
      background-color: #d0021b;
    }
    &.button--green {
      background-color: #08a84f;
    }
    &.button--rounded{
      border-radius: 20px;
    }
    &.button--underlined{
      text-decoration: underline;
    }

    &.button--min-width {
      min-width: 140px;
    }

    &.button--full-width {
      width: 100%;
    }

    &:focus{
      outline: none;
    }

    &:disabled{
      opacity: .5;
    }
  }
