.liveroom{
    .grid-cols {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;
        display: grid;
        align-items: center;
    }
    .grid-cols-small {
        @media (max-width: 1024px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
    }
}
