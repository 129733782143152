.ha-discounts, .ha-discounts-new {
    .discounts-table {
        font-size: 14px;
        tr.inactive td {
            color: #842029;
            background-color: #f8d7da;
            border-color: #f5c2c7;
        }

        tr.active td {
            color: #0f5132;
            background-color: #d1e7dd;
            border-color: #badbcc;
        }

        tr.expired td {
            color: #664d03;
            background-color: #fff3cd;
            border-color: #ffecb5;
        }

        tr.preproduction td {
            color: #004085;
            background-color: #cce5ff;
            border-color: #b8daff;
        }
    }
    .form-control {
        .react-datepicker-wrapper{
            width: 100%;
        }
    }
    .calendar {
        position: relative;
        .form-control {
            input  {
                width: 100%;
                border: none;
                outline: none;
            }
        }
        .icon-calendar {
            cursor: pointer;
            position: absolute;
            right: 11px;
            top: 40px;
            z-index: 10;
        }
    }
    .chip_ocusers_option{
        padding: 5px;
        margin: 2.5px;
        font-size: 14px;
    }
}

.ha-discounts-new {
    .calendar {
        .icon-calendar {
            right: 20px;
            top: 10px;
        }
    }
}

.form-automatic-discounts, .form-automatic-discounts label, .form-automatic-discounts input, .form-automatic-discounts select{
    font-size:14px;
}