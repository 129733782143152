.ha-whitelabels{
    .table{
        tr.rejected td{
            color: #842029;
            background-color: #f8d7da;
            border-color: #f5c2c7;
        }
        tr.redirected td {
            color: #984c0c;
            background-color: #ffe5d0;
            border-color: #fecba1;
        }
        tr.validated td{
            color: #0c5460;
            background-color: #d1ecf1;
            border-color: #bee5eb;
        }
        tr.pending td{
            color: #664d03;
            background-color: #fff3cd;
            border-color: #ffecb5;
        }
        tr.production td {
            color: #0f5132;
            background-color: #d1e7dd;
            border-color: #badbcc;
        }
    }
    .alert-redirected {
        color: #984c0c;
        background-color: #ffe5d0;
        border-color: #fecba1;
    }
}