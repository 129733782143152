.docs-cards{
    .card-footer{
        background:none;
        border-top: none;
    }
    @media (max-width: 1020px){
        display: block !important;
    }
    a.button svg {
        margin-right: 0.5rem;
        path {
            
            fill: white;
        }
    }
}
