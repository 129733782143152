@import "../../../assets/styles/colors";

.legal-record{
    section {
        padding: 50px 0;
    }

    section.main {
        position: relative;
        padding-bottom: 80px;
        padding-top: 0;
        background-color:#000;
    }


    section.main .main-content {
        padding-top: 180px;
    }
    .main-content {
        text-align: center;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        h1{
            color: $white;
            margin-bottom: 40px;
        }
    }
}
