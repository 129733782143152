.card {
    margin-bottom: 20px;
    width: 100%;
    border: solid 1px #c8d1db !important;
    &.table {
        border: none !important;
        margin-bottom: 20px;
        .card-header {
            border: solid 1px #c8d1db;
            border-bottom: none;
        }
        .card-body { padding: 0; }
    }
    .card-header {
        position: relative;
        height: auto;
        background-color: #f7f7f7;
        border-bottom: solid 1px #c8d1db !important;
        h1 {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.5px;
            text-align: start;
            color: #4b5056;
        }
        @media (max-width: 350px){
            h1 { font-size: 14px; }
        }
        .close-modal {
            position: absolute;
            right: 12px;
            cursor: pointer
        }
    }
    .card-body {
        .button-content {
            display: flex;
            justify-content: center;
            margin-top: 30px;
            button{
                margin: 0 10px;
            }
            button:nth-child(2){
                //margin-left: 50px;
            }
            @media (max-width: 400px){
                flex-direction: column;
                margin: 0;
                button:nth-child(2){
                    margin: 20px 0 0 0;
                }
            }
        }
        .img-notice {
            width: 100%;
            height: 100%;
        }
    }
}
