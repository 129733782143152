.facturacion-content {
    width: 100%;
    .card { margin-bottom: 20px; }
    .data-content {
        .card p {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: -0.5px;
            text-align: justify;
            color: #4b5056;
        }
        .card:nth-child(1) {
            p { margin: 0 }
            margin-right: 20px
        }
    }
    .card {
        .resum-text-content {
            p {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.5px;
                text-align: justify;
                color: #4b5056;
                &.sub { margin-left: 25px; }
            }
            p:nth-child(4){
                margin-bottom: 0;
            }
        }
        .table-content {
            overflow: auto;
            width: 100%;
            .table {
                text-align: center;
                font-size: 14px;
                margin: 0;
                th {
                    color: #4b5056;
                    background-color: #ececec;
                    border: solid 1px #c8d1db;
                    vertical-align: inherit;
                    text-transform: uppercase;
                    &.pending { color: #0b648d; background-color: #70d2ff; }
                    &.paid { color: #0b652b; background-color: #6def9c; }
                    border-top: none;
                    svg {
                        margin-right: 6px;
                    }
                }
                td {
                    color: #4b5056;
                    border: solid 1px #c8d1db;
                    &.title { background-color: #f7fafe; }
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: -0.5px;
                    text-align: center;
                    color: #4b5056;
                    span { font-weight: normal; }
                    &.title { text-align: start; }
                }
                .par{
                    background-color:#ffffff;
                }
                .impar{
                    background-color: #fcfcfc;
                }
                th, td {
                    padding: 8px;
                    width: 50%;
                }
            }
        }
        .cards {
            .card-content { padding: 2px 10px;}
            @media (max-width: 800px){
               display: block;
               .card-content {
                    max-width: 100%;
               }
            }
        }
    }
    .facturacion-cont-top {
        padding: 0px 20px;
        p {
            font-size: 16px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.5px;
            text-align: justify;
            color: #4b5056;
        }
    }
    .billing-resum-content {
        .card{
            margin: 0;
            &.table { margin-bottom: 20px; }
        }

        .card .card-body {
            padding: 0;
            .cards { padding: 20px 25px 0 25px; }
            .pendend-pay-content {
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
                height: 39px;
                display: flex;
                align-items: center;
                background-color: #f4f9ff;
                span {
                    height: 100%;
                    width: 144px;
                    background-color: #f7f7f7;
                    border-right: 1px solid rgba(0, 0, 0, 0.125);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: bold;
                    cursor: pointer;
                    &:hover, &.active {
                        background-color: #fcfdff;
                    }
                    svg { margin-right: 6px; }
                    @media (max-width: 500px){ width: 50%; }
                }
                @media (max-width: 500px){
                    span:nth-child(2){ border: none }
                }
            }
        }
    }
    .paginate-content { margin-bottom: 20px; }
}
