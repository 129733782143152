@import "../../assets/styles/colors";

.public-layout{
    header.header{
        background-color: transparent;
        color: #fff;
        font-size:13px;
        font-weight:bold;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 4;
        padding: 40px 0;
    }

    .navbar-light .navbar-toggler{
        color: rgba(255, 255, 255, 0.6);
        border: none;
        transition: all .3s ease;
    }

    .navbar-light .navbar-toggler:focus{
        border: none;
        outline: none;
    }

    @media (max-width: 991px) {
        .navbar-collapse{
            background-color: rgba(0, 0, 0, 0.95);
            border-radius:20px;
            padding:5rem;
        }
    }

    .navbar-toggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='-2 -2 18 18'><path opacity='.8' fill='%23ffffff' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z'/></svg>");
        transition: all .3s ease-in-out;
    }

    .collapsed .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.8%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        transition: all .3s ease-in-out;
    }


    header.header .menu .active{
        color: $orange !important;
    }

    header.header .menu .nav-link{
        color: #fff;
        transition: all .3s ease;
    }

    header.header .menu .nav-link:hover{
        color: $orange;
    }

    .nav-link {
        border-radius: 50px;
        padding: 8px 20px !important;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
    }
    .lang-selector{
        position: absolute;
        top: 0;
        z-index: 5;
        text-align: right;
        span{
            padding: 0 10px;
            cursor: pointer;
        }
    }


    .btn {
        padding: 8px 34px;
        border-radius: 50px;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: center;
    }

    .btn:hover{
        color: #212529 !important;
    }

    a.btn-transparent {
        color: $orange;
    }

    .btn-transparent {
        background-color: transparent;
        border: 2px solid #fff;
        /* background-image: linear-gradient(to bottom, #13ffb4, #00cbbe); */
        color: #fc531d !important;
        text-transform: uppercase;
    }

    .btn-transparent:hover {
        background-color: #fff;
        color: #000 !important;
    }

    @media (max-width: 575px) {
        .main h1 {
            padding: 0 31px;
        }
        .main .btn-wrapper {
            padding: 0 15px;
        }
        .main .btn-wrapper .btn-default {
            width: 100%;
        }
    }
}
