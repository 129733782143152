@import "../../../assets/styles/colors";
/* VALUES SECTION*/
section.values{
    /* padding: 120px 0 120px; */
    h2{
        text-align:center;
    }
    p.subtitle{
        color:$orange;
        text-align: center;
    }

    .icon {
        display: inline-block;
        /* float: left; */
    }

    /*.thumbs-wrapper {
        padding: 64px 0;
    }*/

    .thumbs-wrapper .thumb {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        align-items: center;
    }

    .thumbs-wrapper .text {
        margin-left: 20px;
    }

    /* .thumbs-wrapper h4 {
        padding-left: 30px;
        font-size: 16px;
        line-height: 1.3em;
        text-align: justify;
    } */

    .thumbs-wrapper .icon {
        flex: 0 0 auto;
        /* margin-left: -30px; */
        /* width: 70px; */
    }

    .thumbs-wrapper .icon img {
        width: auto;
        height: 67px;
    }

    .thumbs-wrapper .thumb-title,
    .thumbs-wrapper .thumb-subtitle {
        color: #372454;
        /* padding-left: 15px; */
    }

    .thumbs-wrapper .thumb-title {
        display: block;
        font-weight: bold;
        font-size: 20px;
        padding-top: 0;
        padding-bottom: 12px;
        /* padding-left: 15px; */
    }

    .thumbs-wrapper .thumb-subtitle {
        font-size: 14px;
        margin-top: 10px;
    }

    @media (min-width: 768px) {
        /* .thumbs-wrapper .thumb:nth-child(odd) {
            padding-right: 70px;
        }
        .thumbs-wrapper .thumb:nth-child(even) {
            padding-left: 70px;
        } */
    }

    @media (max-width: 991px) {
        .thumbs-wrapper {

        }
        .thumbs-wrapper .icon img {
            height: 50px;
        }
        .thumbs-wrapper .thumb-title {
            /* width: calc(100% - 60px); */
            font-size: 14px;
        }

        .thumbs-wrapper .thumb-subtitle {
            font-size: 11px;
        }
    }

    @media (max-width: 567px) {
        .thumbs-wrapper {
            padding: 20px 0;
        }
        .thumbs-wrapper .thumb-title {
            font-size: 14px;
        }

        .thumbs-wrapper .thumb-subtitle {
            font-size: 10px;
        }
    }
}
