// principal page
.inicio {
    width: 100%;
    .card { margin-bottom: 20px; }
    .stats-content {
        display: flex;
        .card { margin: 0; }
        .card:nth-child(1){
            //margin-right: 10px;
            .header-content { margin-bottom: 42px; }
        }
        .card:nth-child(2){ margin-left: 10px; }
        .olecams-content, .mow-content {
            .header-content {
                display: flex;
                justify-content: center;
                margin-bottom: 30px;
            }
            .header-content { height: 10%; }
            .main-content { height: 63%; 
                .price-total {
                    color: #fc531d;
                }
                .softblue {
                    color: #55929e !important;
                }
            }
            .footer-content{
                height: 10%;
                width: 100%;
                
                
                @media (min-width: 700px){
                    .home--alignBottom{ margin-top:10.5rem;}
                }

            }
            .main-content, .footer-content {
                text-align: center;
                p, h1 {
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: -0.5px;
                    text-align: center;
                    color: #4b5056;
                }
                p {
                    margin: 0px 0px 10px 0px;
                    font-size: 18px;
                }
                h1 {
                    margin-bottom: 30px;
                    font-size: 30px;
                    font-weight: bold;
                }
                button { margin: 0 !important; }
            }
        }
        @media (max-width: 700px){
            flex-direction: column;
            .card { margin: 0 !important; }
            .card:nth-child(2){ margin-top: 20px !important; }
        }
    }
    .stats {
        display: flex;
        span {
            width: 50%;
            text-align: center;
            p, h1, h2 {
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: -0.5px;
                text-align: center;
                color: #4b5056;
            }
            p {
                margin: 0px 0px 10px 0px;
                font-size: 18px;
            }
            h1 {
                font-size: 30px;
                font-weight: bold;
            }
            h2 {
                font-size: 18px;
                &.movil { display: none; }
            }
            @media (max-width: 500px){
                h2 {
                    &.desktop { display: none; }
                    &.movil { display: block; }
                }
            }
        }
        span:nth-child(2) { border-left: 1px solid #cdd0d5; }
        span:nth-child(1) { border-right: 1px solid #cdd0d5; }
        @media (max-width: 500px){
            h1 { font-size: 20px !important; }
            h2 { font-size: 15px !important; }
        }
    }
    .stats-broker{
        h1 {
            font-size: 30px;
            font-weight: bold;
        }
        h2 {
            font-size: 18px;
        }
    }
}
