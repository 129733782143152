.ha-visa-incidents{
    table{
        tr.pointer{
            cursor: pointer;
        }
    }
}

.modal-dialog{
    .srv-validation-message{
        color: #ff043a;
    }
}

.card{
    .card-header.pointer{
        cursor: pointer;
    }
}