.data-content {
    width: 100%;
    display: flex;
    .input-checkbox { display: block; }
    textarea { resize: none; height: 80px !important; }
    .textarea-api_token{ height: 150px !important; }
    .left {
        margin-right: 10px;
        .card { margin: 0; }
    }
    .right {
        margin-left: 10px;
        .card { margin-bottom: 20px; }
    }
    @media (max-width: 1200px){
       flex-direction: column;
    }
    .right, .left {
        width: 50%;
        .button-content { text-align: center;}
        @media (max-width: 1200px){
           margin: 0;
           width: 100%;
         }
     }
}



.table-content {
    width: 100%;
    height: auto;
    overflow: auto;
    .table {
        text-align: center;
        font-size: 14px;
        margin: 0 0 20px 0;
        th,.total {
            color: #4b5056;
            background-color: #ececec;
            border: solid 1px #c8d1db;
            width: auto;
        }
        .total {
            font-weight: bold;
        }
        td {
            color: #4b5056;
            border: solid 1px #c8d1db;
            vertical-align: middle;
            .actions { cursor: pointer; }
            svg { cursor: pointer; }
            .button-content {
                display: flex;
                justify-content: center;
                @media (max-width: 800px){
                    flex-direction: column;
                    align-items: center;
                    button:first-child {
                        margin: 0 0 10px 0;
                    }
                }
            }
        }
        td.hover{
            cursor: pointer;
            &:hover { background-color: #ececec; }
        }
        .par{
            background-color:#ffffff;
        }
        .impar{
            background-color: #fcfcfc;
        }
        th, td {
            span { display: block; }
        }
        .empty {
            td { height: 39px; }
        }
        button:first-child { margin-right: 20px; }
        button {
            height: 30px; padding: 0;
            svg{
                width:24px;
                height:auto;
                padding-right: 0.2rem;
            }
        }
    }
}

.documentation{
    a{
        margin: 0 10px;
        background: #007bff;
    }
    svg path{
        fill: white;
    }
}
