.devbug-content {
    position: absolute;
    padding: 20px;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(25, 36, 50, 0.7);
    .card {
        width: 500px;
        height: max-content;
        margin: 0;
        @media (max-width: 500px){  
            width: 100%;
        }
    }
}