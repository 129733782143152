#title { display: none;}

.background-modal{
    background-color: rgba(0, 0, 0, .7);
    top: 0;
    left: 0;
    position: fixed;
    z-index: 101;
}
.contract-modal{
    top:0;
    left:0;
    position:fixed;
    z-index: 101;
    align-items: center;
    justify-content: center;
    iframe {
            width: 100%;
            min-height: 350px;
        }
    h1 {
            font-size: 14px;
        }
    .card{
        overflow: hidden;
        display: flex;
        width: 90%;
        max-width: 1024px;
        max-height: 90vh;
        .card-body{
            overflow-y: auto;
        }
    }
}
.avis-content {
    position: absolute;
    background-color: rgba(25, 36, 50, 0.7);
    width: 100%;
    height: unset;
    top: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    &.hidden {
        display: none;
    }
    iframe {
        width: 100%;
        height: 350px;
    }
    .card {
        width: 90%;
        max-width: 1024px;
        max-height: 90vh;
        margin-top: 93px;
        margin-bottom: 0;
        overflow: hidden;
        border: solid 1px #c8d1db;
        .card-body { padding: 0; overflow-y: auto; }
        .card-header {
            height: 39px;
            background-color: #f7f7f7;
            @media (max-width: 350px){
                h1 { font-size: 14px; }
            }
        }
        .text-content {
            padding: 1.25rem;
            &.contract { padding: 0; }
        }
        .text-notice {
            h1, p { 
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.5px;
                text-align: justify;
                color: #4b5056;
                margin-bottom: 20px;
            }
            p:nth-child(3) { margin: 0; }
            h1 {
                font-size: 16px;
                font-weight: bold;
            }
        }
        .button-content {
            margin-bottom: 20px;
            margin-top: 20px;
            button{ width: 200px;}
            @media (max-width: 360px){  
               display: block;
               button:nth-child(2) {
                 margin: 20px 0px 0px 0px;
               }
            }
        }
    }
}