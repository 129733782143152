.card-container {
    width: 580px;
    margin-top: 25px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.5px;
    text-align: justify;
    color: #4b5056;
    .button-content, .alert {
        text-align: center;
    }
    .button-content { margin-bottom: 0;}
    .chat-assistent {
        float: right;
        cursor: pointer;
        text-decoration: underline;
    }
    .alert {
        font-weight: bold;
        &.blocked {
            display: flex;
            flex-direction: column;
        }
    }
    .text-suport-content {
        text-align: center;
        p { margin: 10px 0px 0px 0px;  text-align: center; }
        p, a{
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.79;
            letter-spacing: -0.5px;
            color: #4b5056;
        }
        a {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.57px;
            color: #fc531d;
         }
    }
    .error-input {
        border: 1px solid #f8d7da;
    }
    .recovery-remember-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em;
        .recovery-password {
            cursor: pointer;
            color: #fc531d;
            &:hover {
                color: #fc531d;
            }
        }
        span {
            display: flex;
            align-items: center;
            input {
                margin-left: 10px;
                 cursor: pointer;
            }
        }
    }
}
