
.herramientas-administrativas-content {
    .table-content {
        width: 100%;
        height: auto;
        overflow: auto;
        .table {
            text-align: center;
            font-size: 14px;
            margin: 0 0 20px 0;
            min-width: 600px;
            th,.total {
                color: #4b5056;
                background-color: #ececec;
                border: solid 1px #c8d1db;
                width: auto;
            }
            .total {
                font-weight: bold;
            }
            td {
                color: #4b5056;
                border: solid 1px #c8d1db;
                vertical-align: middle;
                .actions { cursor: pointer; }
                svg { cursor: pointer; }
                .button-content {
                    display: flex;
                    justify-content: center;
                    @media (max-width: 800px){
                        flex-direction: column;
                        align-items: center;
                        button:first-child {
                            margin: 0 0 10px 0;
                        }
                    }
                }
            }
            td.hover{
                cursor: pointer;
                &:hover { background-color: #ececec; }
            }
            .par{
                background-color:#ffffff;
            }
            .impar{
                background-color: #fcfcfc;
            }
            th, td {
                span { display: block; }
            }
            .empty {
                td { height: 39px; }
            }
            button:first-child { margin-right: 20px; }
            button { height: 30px; padding: 0; }
        }
    }

}

.form-content {
    .form-detall-content {
        &.user-detall {
            display: flex;
            @media (max-width: 941px){
                flex-direction: column;
            }
            @media (max-width: 480px){
                .content-one {
                    flex-direction: column;
                    .form-group { margin: 0; width: 100%; }
                }
                .buttons-content { margin: 0; }
            }
            @media (max-width: 400px){
                .buttons-content {
                    flex-direction: column;
                    button { width: 100%; margin: 20px 0px;}
                    button:first-child { margin: 20px 0px 0px 0px; }
                }
            }
        }
        &.register-detall {
            @media (max-width: 480px){
                .content-one {
                    flex-direction: column;
                    .form-group { margin: 0; width: 100%; }
                }
                .buttons-content { margin: 0; }
            }
            @media (max-width: 400px){
                .buttons-content {
                    flex-direction: column;
                    button { width: 100%; margin: 20px 0px;}
                    button:first-child { margin: 20px 0px 0px 0px; }
                }
            }
        }
        &.vce {
            @media (max-width: 500px){
                .content-one, .content-two {
                    flex-direction: column;
                    align-items: inherit;
                    .form-group { margin: 0; }
                }
            }
            @media (max-width: 400px){
                .buttons-content {
                    flex-direction: column;
                    button { width: 100%; margin: 0;}
                    button:first-child { margin-bottom: 20px; }
                }
            }
        }
        .buttons-content { margin-top: 27px; button.left { margin-right: 20px;} }
        .form-group { margin-bottom: 0;}
        .content-one, .content-two { display: flex; align-items: center;}
    }
    .form-group {
        margin-right: 15px;
        label {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: -0.5px;
            text-align: justify;
            color: #4b5056;
        }
        input, select{
            border: solid 1px #4b5056;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.5px;
            color: #4b5056;
            &::placeholder {
                color: #4b5056;
            }
        }
        .opuscapita_input-addon-button{ display: none }
    }
    .calendar {
        position: relative;
        .form-control {
            border: solid 1px #4b5056;
            input  {
                width: 100%;
                border: none;
                outline: none;
            }
        }
        .icon-calendar {
            cursor: pointer;
            position: absolute;
            right: 11px;
            top: 40px;
            z-index: 10;
        }
    }
    .opuscapita_date-input-field {
        border: solid 1px #4b5056;
        input{
            border: none;
        }
    }
}

.confirm-button{
    display: flex;
    justify-content: center;
    margin: 30px;
}
