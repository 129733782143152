@import "~bootstrap/scss/bootstrap";
@import "./assets/styles/colors";

.card-header {
    display: flex;
    align-items: center;
    h1 {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        text-align: justify;
        color: #4b5056;
        margin: 0px 0px 0px 10px;
    }
    .back-button{
        color: #4b5056;
    }
}

.cursorDefault{
    cursor: default !important;
}

.cursorPointer{
    cursor: pointer !important;
}

a{
    color: $orange;
    text-decoration: none;
    background-color: transparent;
}


a:hover{
    text-decoration: none !important;
    color:#fc531d !important;
}

a.button:hover{
    color: #fff !important;
}


.form-input-success{
    border: 1px solid #20c997
}
.form-input-error {
    border: 1px solid #ff043a
}