footer {
    bottom: 0;
    width: 100%;
    background-color: #282828;
    padding: 20px;
    color: #fbfbfb;
    font-size: 14px;
    z-index: 10;
    @media (max-width: 900px){ position: initial;}
    p, a, span {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.5px;
        text-align: center;
        color: #fbfbfb;
    }
    a:hover { color: #a2a9b0 }
    .footer-idiomas {
        display: flex;
        justify-content: center;
        span {
            margin-left: 5px;
            text-decoration: none;
            outline: none;
            cursor: pointer;
            &.en { margin-right: 5px; }
            &.active { color: #fc531d }
        }
    }
    .footer-links {
        display: flex;
        justify-content: center;
        .text-off-app { display: flex;}
        p { margin-right: 5px;}
        a {
            margin-left: 5px;
            text-decoration: none;
            outline: none;
            &.legal, &.privat { margin-right: 5px; }
        }
        @media (max-width:580px){
            flex-direction: column;
            align-items: center;
            p { margin-right: 0; margin-bottom: 10px;}
            .text-off-app { margin-bottom: 10px;}
        }
    }
}