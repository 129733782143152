
.herramientas-administrativas-content {
    .users-oc-header-content{
        .first-filters{
            display: flex;
            align-items: center;
            /*svg{
                position: absolute;
                top: 8px;
                right: 14px;
                cursor: pointer;
            }*/
            .form-group{
                margin-right: 15px;
            }
        }
    }
    .olecams-user-table-content {
        width: 100%;
        height: auto;
        overflow: auto;
        .table {
            text-align: center;
            font-size: 14px;
            margin: 0 0 20px 0;
            min-width: 600px;
            th,.total {
                color: #4b5056;
                background-color: #ececec;
                border: solid 1px #c8d1db;
                width: auto;
            }
            .total {
                font-weight: bold;
            }
            td {
                color: #4b5056;
                border: solid 1px #c8d1db;
                vertical-align: middle;
                .actions { cursor: pointer; }
                svg { cursor: pointer; }
                .button-content {
                    display: flex;
                    justify-content: center;
                    @media (max-width: 800px){
                        flex-direction: column;
                        align-items: center;
                        button:first-child {
                            margin: 0 0 10px 0;
                        }
                    }
                }
            }
            td.hover{
                cursor: pointer;
                &:hover { background-color: #ececec; }
            }
            .par{
                background-color:#ffffff;
            }
            .impar{
                background-color: #fcfcfc;
            }
            th, td {
                span { display: block; }
            }
            .empty {
                td { height: 39px; }
            }
            button:first-child { margin-right: 20px; }
            button { height: 30px; padding: 0; }
        }
    }

}

.user-detail-modal-body{
    .left {
        margin-right: 10px;
        .card { margin: 0; }
    }
    .right {
        margin-left: 10px;
        .card:first-child { margin-bottom: 20px; }
    }
    @media (max-width: 900px){
       flex-direction: column;
    }
    .right, .left {
        width: 50%;
        .button-content { text-align: center;}
        @media (max-width: 900px){
           margin: 0;
           width: 100%;
         }
     }
}

.oc-users-modal {
    display: flex;
    justify-content: center;
    textarea { resize: none; height: 200px !important; }
    .swith-label { display: block; }
    .card {
        width: 900px;
        height: max-content;
    }

        .table {
            text-align: center;
            font-size: 14px;
            margin: 0 0 20px 0;
            min-width: 600px;
    
            th,
            .total {
                color: #4b5056;
                background-color: #ececec;
                border: solid 1px #c8d1db;
                width: auto;
            }
    
            .total {
                font-weight: bold;
            }
    
            td {
                color: #4b5056;
                border: solid 1px #c8d1db;
                vertical-align: middle;
    
                .actions {
                    cursor: pointer;
                }
    
                svg {
                    cursor: pointer;
                }
    
                .button-content {
                    display: flex;
                    justify-content: center;
    
                    @media (max-width: 800px) {
                        flex-direction: column;
                        align-items: center;
    
                        button:first-child {
                            margin: 0 0 10px 0;
                        }
                    }
                }
            }
    
            td.hover {
                cursor: pointer;
    
                &:hover {
                    background-color: #ececec;
                }
            }
    
            .par {
                background-color: #ffffff;
            }
    
            .impar {
                background-color: #fcfcfc;
            }
    
            th,
            td {
                span {
                    display: block;
                }
            }
    
            .empty {
                td {
                    height: 39px;
                }
            }
    
            button:first-child {
                margin-right: 20px;
            }
    
            button {
                height: 30px;
                padding: 0;
            }
        }
}

.info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border: solid 1px #c8d1db;
    background-color: #fcfdff;
    margin: 20px auto 0 auto;
    width: 700px;
    @media (max-width: 950px){
        width: 100%;
    }
    .info-one{
        @media (max-width: 700px){
            margin-bottom: 10px;
        }
     }
    .info-one, .info-two {
        display: flex;
        span {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: -0.5px;
            color: #4b5056;
        }
        .margin { margin-right: 30px; }
        @media (max-width: 630px){
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            width: 100%;
            .margin { margin: 0;}
            .content-one {
                margin-bottom: 10px;
            }
        }
        @media (max-width: 380px){
            grid-template-columns: initial;
        }
    }
}
