
.card { margin: 0; }
.support-card-content {
    display: flex;
    .card:nth-child(2) {
        margin: 0 20px;
    }
    .card {
        margin: 0;
        .card-header { h1 { margin: 0;} }
        .text-content {
            align-items: center;
            height: 100%;
            p {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.5px;
                text-align: center;
                color: #4b5056;
                margin: 0;
                &.footer-text {
                    font-size: 12px;
                }
            }
            h2 {
                font-size: 27px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.5px;
                text-align: center;
                color: #fc531d;
                margin: 40px 0;
            }
            svg { margin: 20px 0; }
            button { width: 200px; max-width: 100%; }
        }

    }
    @media (max-width: 1024px){ 
        display: block;
        .card {
            margin: 0 0 20px 0;
        }
        .card:nth-child(2) {
            margin: 0 0 20px 0;
        }
        .card:nth-child(3) {
            margin: 0;
        }
    }
}

.form-content-content {
    display: flex;
    justify-content: center;
    width: 100%;
    .card {
        width: 700px;
        height: max-content;
        textarea {
            resize: none;
            height: 200px;
        }
        .error-input {
            border: 1px solid #e74c3c;
        }
        @media (max-width: 800px){
            width: 100%;
        }
    }
}