.validate_aff{
    .table th{
        width: 15% !important;
    }
}
.herramientas-administrativas-content {
    width: 100%;
    .card {
        margin-bottom: 20px;
        height: fit-content;
        .banner-header-content {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            &.key {
                flex-direction: column;
                h2 { margin-top: 20px;}
            }
            @media (max-width: 382px){
                form {width: 100%;}
            }
            .input-content {
                margin: 0;
                position: relative;
                input {
                    min-width: 140px;
                    width: 300px;
                    height: 40px;
                    padding-right: 40px;
                    @media (max-width: 382px){
                        width: 100%;
                    }
                }
                svg {
                    position: absolute;
                    top: 8px;
                    right: 14px;
                    cursor: pointer;
                }
            }
            button {
                width: 300px;
                height: 40px;
                @media (max-width: 382px){
                    width: 100%;
                }
            }
            @media (max-width: 750px){
                flex-direction: column;
                .input-content { margin-bottom: 20px; }
            }
        }
        .current-file {
            img {
                width: 140px;
                height: 140px;
            }
        }
    }
    .banner-table-content {
        width: 100%;
        height: auto;
        overflow: auto;
        .table {
            text-align: center;
            font-size: 14px;
            margin: 0 0 20px 0;
            min-width: 600px;
            th,.total {
                color: #4b5056;
                background-color: #ececec;
                border: solid 1px #c8d1db;
                width: 25%;
            }
            .total {
                font-weight: bold;
            }
            td {
                color: #4b5056;
                border: solid 1px #c8d1db;
                vertical-align: middle;
                .actions { cursor: pointer; }
                svg { cursor: pointer; }
                .button-content {
                    display: flex;
                    justify-content: center;
                    @media (max-width: 800px){
                        flex-direction: column;
                        align-items: center;
                        button:first-child {
                            margin: 0 0 10px 0;
                        }
                    }
                }
            }
            .par{
                background-color:#ffffff;
            }
            .impar{
                background-color: #fcfcfc;
            }
            th, td {
                span { display: block; }
            }
            .empty {
                td { height: 39px; }
            }
            button:first-child { margin-right: 20px; }
            button { height: 30px; padding: 0; }
        }
    }
    .register-banner-modal {
        display: flex;
        justify-content: center;
        textarea { resize: none; height: 200px !important; }
        .swith-label { display: block; }
        .card {
            width: 700px;
            height: max-content;
        }
    }
}