@import "../../../assets/styles/colors";

.main-white-brand{
    form{
        label, input, select, .input-group-text{
            font-size: 12px !important;
        }
        .alert-info{
            font-size:11px !important;
        }
        .form-group.required .form-label .text:after {
            content:" *";
            color:red;
        }
        .underlined:hover{
            text-decoration:underline !important;
        }
        .orange{
            color: $orange;
        }
    }
    .metatags-accordion{
        .btn-link{
            color: $orange;
        }

        .nav-link.active{
            color: #fff;
            background-color: $orange;
        }
        .nav-link.active:hover{
            color: #fff !important;
            background-color: $orange;
        }
    }

    .table{
        tr.rejected td{
            color: #842029;
            background-color: #f8d7da;
            border-color: #f5c2c7;
        }
        tr.validated td{
            color: #0f5132;
            background-color: #d1e7dd;
            border-color: #badbcc;
        }
        tr.pending td{
            color: #664d03;
            background-color: #fff3cd;
            border-color: #ffecb5;
        }
    }

    .lang-button {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #fc531d;
        height: 2.5rem;
        flex: 1 1 auto;
        margin-bottom: -1px;
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        background: transparent;
    }

    .lang-button:hover {
        border-color: 1px solid #fc531d;
    }

    .lang-button.active {
        color: #fff;
        background-color: #fc531d;
    }
}