.main-roomlist{
    form{
        label, input, select, .input-group-text{
            font-size: 12px !important;
        }
        .alert-info{
            font-size:11px !important;
        }
    }
    .iframe_body{
        margin: 20px auto !important;
    }
}

.roomlist-setup{
    .btn-group{
        width:100%;
    }
    .design-form-card{
        button.btn-red{
            color: white;
            background-color: #d9534f;
            border-color: #d43f3a;
        }
        button.btn-red:hover, button.btn-red:focus {
            background-color: #c70800;
            border-color: #940600;
        }
        .olecams-design{
            color: #fff;
            background-color: #FF7700;
            border-color: #FC7447;
        }
    }
}