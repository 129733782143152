@import '~react-datepicker/src/stylesheets/datepicker.scss';
.stats { width: 100%;}
.statsolecams-content {
    width: 100%;
    .card { margin-bottom: 20px; }
    .opuscapita_date-input__variants-btn {
       display: none;
    }
    .stats-summary {
        display: flex;
        span {
            width: 50%;
            text-align: center;
            p, h1, h2 {
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1;
                letter-spacing: -0.5px;
                text-align: center;
                color: #4b5056;
            }
            p {
                margin: 0px 0px 10px 0px;
                font-size: 18px;
            }
            h1 {
                font-size: 30px;
                font-weight: bold;
            }
            h2 { font-size: 18px; }
        }
        span:nth-child(2) {
            border-left: 1px solid #cdd0d5;
            border-right: 1px solid #cdd0d5;
        }
        @media (max-width: 750px){
           h2 { font-size: 15px !important; }
           h1 { font-size: 20px !important; }
        }
        @media (max-width: 500px){
            flex-direction: column;
            span { width: 100%; }
            span:nth-child(2) {
                border-left: 0;
                border-right: 0;
                border-top: 1px solid #cdd0d5;
                border-bottom: 1px solid #cdd0d5;
                h2 { margin-top: 0.5rem; }
            }
            span:nth-child(3) {
                h2 { margin-top: 0.5rem; }
                h1 { margin: 0; }
            }
        }
    }
    .stats-ol {
        .first-filters {
            display: flex;
            align-items: center;
            .sp-select {
                margin-top: 8px;
            }
        }
        .form-content {
            .form-detall-content {
                &.user-detall {
                    display: flex;
                    @media (max-width: 941px){
                        flex-direction: column;
                    }
                    @media (max-width: 480px){
                        .content-one {
                            flex-direction: column;
                            .form-group { margin: 0; width: 100%; }
                        }
                        .buttons-content { margin: 0; }
                    }
                    @media (max-width: 400px){
                        .buttons-content {
                            flex-direction: column;
                            button { width: 100%; margin: 20px 0px;}
                            button:first-child { margin: 20px 0px 0px 0px; }
                        }
                    }
                }
                &.register-detall {
                    @media (max-width: 480px){
                        .content-one {
                            flex-direction: column;
                            .form-group { margin: 0; width: 100%; }
                        }
                        .buttons-content { margin: 0; }
                    }
                    @media (max-width: 400px){
                        .buttons-content {
                            flex-direction: column;
                            button { width: 100%; margin: 20px 0px;}
                            button:first-child { margin: 20px 0px 0px 0px; }
                        }
                    }
                }
                &.vce {
                    @media (max-width: 500px){
                        .content-one, .content-two {
                            flex-direction: column;
                            align-items: inherit;
                            .form-group { margin: 0; }
                        }
                    }
                    @media (max-width: 400px){
                        .buttons-content {
                            flex-direction: column;
                            button { width: 100%; margin: 0;}
                            button:first-child { margin-bottom: 20px; }
                        }
                    }
                }
                .buttons-content { margin-top: 27px; button.left { margin-right: 20px;} }
                .form-group { margin-bottom: 0;}
                .content-one, .content-two { display: flex; align-items: center;}
            }
            .form-group {
                margin-right: 15px;
                label {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: -0.5px;
                    text-align: justify;
                    color: #4b5056;
                }
                input, select{
                    border: solid 1px #4b5056;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: -0.5px;
                    color: #4b5056;
                    &::placeholder {
                        color: #4b5056;
                    }
                }
                .opuscapita_input-addon-button{ display: none }
            }
            .calendar {
                position: relative;
                .form-control {
                    border: solid 1px #4b5056;
                    input  {
                        width: 100%;
                        border: none;
                        outline: none;
                    }
                }
                .icon-calendar {
                    cursor: pointer;
                    position: absolute;
                    right: 11px;
                    top: 40px;
                    z-index: 10;
                }
            }
            .opuscapita_date-input-field {
                border: solid 1px #4b5056;
                input{
                    border: none;
                }
            }
        }
        .seconds-and-third-content {
            display: flex;
            @media (max-width: 900px){
                display: initial;
            }
        }
        .seconds-filters, .third-filters {
            display: flex;
            align-items: center;
            &.sp-filter {
                @media (max-width: 930px){
                    flex-direction: column;
                    align-items: self-end;
                }
            }
            .form-group {
                button { margin-top: 29px; }
            }
            .content-tree, .content-four { display: flex; }
        }
        @media (max-width: 480px){
            .first-filters, .seconds-filters, .third-filters {
                flex-direction: column;
                .content-one, .content-two, .content-tree, .content-four { width: 100%; }
                .content-two {
                    .form-group:first-child { margin-right: 10px; }
                }
                .content-tree { flex-direction: column; }
                .form-group {
                    width: 100%;
                    margin: 0;
                    &.rng {
                        margin-top: 10px;
                        label { display: none; }
                    }
                }
            }
            .stats-day {
                margin: 0 !important;
                button { width: 100%;}
            }
            // .form-group { margin: 0 !important; }
        }
    }
    .table-content {
        overflow: auto;
        .table {
            text-align: center;
            font-size: 14px;
            th {
                color: #4b5056;
                background-color: #ececec;
                border: solid 1px #c8d1db;
            }
            td {
                color: #4b5056;
                border: solid 1px #c8d1db;
                .plus { margin: 0 8px; }
                .plus, .eyes { cursor: pointer; }
                .flag {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img { width: fit-content; }
                }
            }
            &.sp td, .vce-td{
                cursor: pointer;
                &:hover { background-color: #ececec; }
            }
            .par{
                background-color:#ffffff;
            }
            .impar{
                background-color: #fcfcfc;
            }
            th, td {
                span { display: block; }
            }
        }
    }
    .info-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        border: solid 1px #c8d1db;
        background-color: #fcfdff;
        margin: 20px auto 0 auto;
        width: 700px;
        @media (max-width: 950px){
            width: 100%;
        }
        .info-one{
            margin-bottom: 20px;
            @media (max-width: 700px){
                margin-bottom: 10px;
            }
         }
        .info-one, .info-two {
            display: flex;
            span {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: -0.5px;
                color: #4b5056;
            }
            .margin { margin-right: 30px; }
            @media (max-width: 630px){
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                width: 100%;
                .margin { margin: 0;}
                .content-one {
                    margin-bottom: 10px;
                }
            }
            @media (max-width: 380px){
                grid-template-columns: initial;
            }
        }
    }
}

table.phone-stats{
    tr.active{
        background-color: #d6ecd2 !important;
    }
}
