.body-wl-preview{
    background: #303030;

    /* HEADER */

    header.site-header {
        background-color: #282828;
        header.site-header * {
            box-sizing: border-box;
        }
        div.wrapper {
            width: 100%;
            padding: 15px;

            div.main-navbar {
                display: flex;
                align-content: center;
                align-items: center
            }
        }
    }

    @media (max-width:767.98px) {
        header.site-header div.wrapper div.main-navbar {
            justify-content: space-between
        }
    }

    header.site-header div.wrapper div.main-navbar img.logo {
        display: block;
        margin: 0;
        max-width: 100%;
        width: auto;
        height: auto
    }

    /* END HEADER */

    /* SIDEBAR */

    li.sidebar-filter {
        border-top: 1px solid #4c4c4c
    }

    li.sidebar-filter.selected div.link {
        background-color: #555;
        color: #fff
    }

    li.sidebar-filter div.link {
        color: #c4c4c4;
        background-color: #3b3b3b;
        text-decoration: none;
        padding: 10px 20px 10px 30px;
        font-size: 14px;
        letter-spacing: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        -webkit-appearance: none;
        cursor: pointer;
        width: 100%;
        outline: none;
        opacity: .6;
    }

    li.sidebar-filter div.link span.label {
        flex-grow: 1;
        flex-shrink: 1;
        text-align: left;
    }

    @media (max-width:769px) {
        .sidebar-overlay.visible {
            position: fixed;
            z-index: 11;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, .5)
        }
    }

    .sidebar {
        background-color: #373737;
        max-width: 370px;
        min-width: 275px;
        transition: transform .5s
    }

    @media (max-width:769px) {
        .sidebar {
            position: fixed;
            z-index: 10;
            overflow-y: scroll;
            width: 80%;
            top: 0;
            left: 0;
            bottom: 0;
            transform: translateX(-100%) translateZ(0);
            z-index: 12
        }
        .sidebar.visible {
            transform: translateX(0) translateZ(0);
            box-shadow: 0 0 20px 0 #000
        }
    }

    .sidebar ul.main-filters {
        margin: 0;
        padding: 0;
        list-style: none
    }

    .sidebar ul.main-filters>li>header {
        position: relative;
        padding: 9px 20px 9px 30px;
        background-color: #303030;
        border-top: 1px solid #4c4c4c;
        color: #e9e9e9;
        letter-spacing: -.5px;
        font-weight: 700;
        font-size: 14px;
        opacity: .6;
    }

    .sidebar ul.main-filters>li>header:before {
        content: "";
        display: block;
        height: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .sidebar ul.main-filters>li>ul {
        list-style: none;
        padding: 0;
        margin: 0;
        overflow: hidden
    }

    /* END SIDEBAR */

    /* LAYOUT */

    #layout-wrapper {
        display: flex;
        flex-direction: column;
        .spacer {
            flex-grow: 1;
            flex-shrink: 1
        }
    }

    #layout-wrapper .sticky-header {
        position: sticky;
        top: 0;
        z-index: 11
    }

    #layout-wrapper .content-with-sidebar {
        display: flex
    }

    #layout-wrapper .content-with-sidebar .content-space {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background-color: #fff;
        padding: 0;
        max-width: 100%
    }

    @media (max-width:769px) {
        #layout-wrapper .content-with-sidebar .content-space .olecams-bar-container {
            display: none
        }
    }

    #layout-wrapper .content-with-sidebar .content-space .view-more-content {
        overflow: auto;
        padding: 50px 0;
        text-align: center;
        display: inline-block;
        width: 100%
    }

    #layout-wrapper .content-without-sidebar {
        background-color: #fff;
        padding: 5px
    }

    #layout-wrapper .empty-page {
        flex: 1;
        background-color: #161616
    }

    /* END LAYOUT */


    .row.row-roomlist {
        margin-right: 1px;
        margin-left: 1px
    }

    .row.row-roomlist .col,
    .row.row-roomlist .col-1,
    .row.row-roomlist .col-2,
    .row.row-roomlist .col-3,
    .row.row-roomlist .col-4,
    .row.row-roomlist .col-5,
    .row.row-roomlist .col-6,
    .row.row-roomlist .col-7,
    .row.row-roomlist .col-8,
    .row.row-roomlist .col-9,
    .row.row-roomlist .col-10,
    .row.row-roomlist .col-11,
    .row.row-roomlist .col-12,
    .row.row-roomlist .col-auto,
    .row.row-roomlist .col-lg,
    .row.row-roomlist .col-lg-1,
    .row.row-roomlist .col-lg-2,
    .row.row-roomlist .col-lg-3,
    .row.row-roomlist .col-lg-4,
    .row.row-roomlist .col-lg-5,
    .row.row-roomlist .col-lg-6,
    .row.row-roomlist .col-lg-7,
    .row.row-roomlist .col-lg-8,
    .row.row-roomlist .col-lg-9,
    .row.row-roomlist .col-lg-10,
    .row.row-roomlist .col-lg-11,
    .row.row-roomlist .col-lg-12,
    .row.row-roomlist .col-lg-auto,
    .row.row-roomlist .col-md,
    .row.row-roomlist .col-md-1,
    .row.row-roomlist .col-md-2,
    .row.row-roomlist .col-md-3,
    .row.row-roomlist .col-md-4,
    .row.row-roomlist .col-md-5,
    .row.row-roomlist .col-md-6,
    .row.row-roomlist .col-md-7,
    .row.row-roomlist .col-md-8,
    .row.row-roomlist .col-md-9,
    .row.row-roomlist .col-md-10,
    .row.row-roomlist .col-md-11,
    .row.row-roomlist .col-md-12,
    .row.row-roomlist .col-md-auto,
    .row.row-roomlist .col-sm,
    .row.row-roomlist .col-sm-1,
    .row.row-roomlist .col-sm-2,
    .row.row-roomlist .col-sm-3,
    .row.row-roomlist .col-sm-4,
    .row.row-roomlist .col-sm-5,
    .row.row-roomlist .col-sm-6,
    .row.row-roomlist .col-sm-7,
    .row.row-roomlist .col-sm-8,
    .row.row-roomlist .col-sm-9,
    .row.row-roomlist .col-sm-10,
    .row.row-roomlist .col-sm-11,
    .row.row-roomlist .col-sm-12,
    .row.row-roomlist .col-sm-auto,
    .row.row-roomlist .col-xl,
    .row.row-roomlist .col-xl-1,
    .row.row-roomlist .col-xl-2,
    .row.row-roomlist .col-xl-3,
    .row.row-roomlist .col-xl-4,
    .row.row-roomlist .col-xl-5,
    .row.row-roomlist .col-xl-6,
    .row.row-roomlist .col-xl-7,
    .row.row-roomlist .col-xl-8,
    .row.row-roomlist .col-xl-9,
    .row.row-roomlist .col-xl-10,
    .row.row-roomlist .col-xl-11,
    .row.row-roomlist .col-xl-12,
    .row.row-roomlist .col-xl-auto,
    .row.row-roomlist .col-xxl,
    .row.row-roomlist .col-xxl-1,
    .row.row-roomlist .col-xxl-2,
    .row.row-roomlist .col-xxl-3,
    .row.row-roomlist .col-xxl-4,
    .row.row-roomlist .col-xxl-5,
    .row.row-roomlist .col-xxl-6,
    .row.row-roomlist .col-xxl-7,
    .row.row-roomlist .col-xxl-8,
    .row.row-roomlist .col-xxl-9,
    .row.row-roomlist .col-xxl-10,
    .row.row-roomlist .col-xxl-11,
    .row.row-roomlist .col-xxl-12,
    .row.row-roomlist .col-xxl-auto {
        position: relative;
        width: 100%;
        padding-right: 1px;
        padding-left: 1px
    }

    .row.row-roomlist .col {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row.row-roomlist .row-cols-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row.row-roomlist .row-cols-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row.row-roomlist .row-cols-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row.row-roomlist .row-cols-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row.row-roomlist .row-cols-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row.row-roomlist .row-cols-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .row.row-roomlist .col-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .row.row-roomlist .col-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .row.row-roomlist .col-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .row.row-roomlist .col-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row.row-roomlist .col-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row.row-roomlist .col-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .row.row-roomlist .col-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row.row-roomlist .col-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .row.row-roomlist .col-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .row.row-roomlist .col-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .row.row-roomlist .col-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .row.row-roomlist .col-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .row.row-roomlist .col-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    @media (min-width:576px) {
        .row.row-roomlist .col-sm {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-sm-1>* {
            flex: 0 0 100%;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-sm-2>* {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .row-cols-sm-3>* {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .row-cols-sm-4>* {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .row-cols-sm-5>* {
            flex: 0 0 20%;
            max-width: 20%
        }
        .row.row-roomlist .row-cols-sm-6>* {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-sm-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%
        }
        .row.row-roomlist .col-sm-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%
        }
        .row.row-roomlist .col-sm-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-sm-3 {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .col-sm-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .col-sm-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%
        }
        .row.row-roomlist .col-sm-6 {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .col-sm-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%
        }
        .row.row-roomlist .col-sm-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%
        }
        .row.row-roomlist .col-sm-9 {
            flex: 0 0 75%;
            max-width: 75%
        }
        .row.row-roomlist .col-sm-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%
        }
        .row.row-roomlist .col-sm-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%
        }
        .row.row-roomlist .col-sm-12 {
            flex: 0 0 100%;
            max-width: 100%
        }
    }

    @media (min-width:768px) {
        .row.row-roomlist .col-md {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-md-1>* {
            flex: 0 0 100%;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-md-2>* {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .row-cols-md-3>* {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .row-cols-md-4>* {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .row-cols-md-5>* {
            flex: 0 0 20%;
            max-width: 20%
        }
        .row.row-roomlist .row-cols-md-6>* {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-md-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%
        }
        .row.row-roomlist .col-md-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%
        }
        .row.row-roomlist .col-md-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-md-3 {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .col-md-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .col-md-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%
        }
        .row.row-roomlist .col-md-6 {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .col-md-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%
        }
        .row.row-roomlist .col-md-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%
        }
        .row.row-roomlist .col-md-9 {
            flex: 0 0 75%;
            max-width: 75%
        }
        .row.row-roomlist .col-md-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%
        }
        .row.row-roomlist .col-md-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%
        }
        .row.row-roomlist .col-md-12 {
            flex: 0 0 100%;
            max-width: 100%
        }
    }

    @media (min-width:992px) {
        .row.row-roomlist .col-lg {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-lg-1>* {
            flex: 0 0 100%;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-lg-2>* {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .row-cols-lg-3>* {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .row-cols-lg-4>* {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .row-cols-lg-5>* {
            flex: 0 0 20%;
            max-width: 20%
        }
        .row.row-roomlist .row-cols-lg-6>* {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-lg-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%
        }
        .row.row-roomlist .col-lg-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%
        }
        .row.row-roomlist .col-lg-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-lg-3 {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .col-lg-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .col-lg-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%
        }
        .row.row-roomlist .col-lg-6 {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .col-lg-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%
        }
        .row.row-roomlist .col-lg-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%
        }
        .row.row-roomlist .col-lg-9 {
            flex: 0 0 75%;
            max-width: 75%
        }
        .row.row-roomlist .col-lg-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%
        }
        .row.row-roomlist .col-lg-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%
        }
        .row.row-roomlist .col-lg-12 {
            flex: 0 0 100%;
            max-width: 100%
        }
    }

    @media (min-width:1200px) {
        .row.row-roomlist .col-xl {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-xl-1>* {
            flex: 0 0 100%;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-xl-2>* {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .row-cols-xl-3>* {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .row-cols-xl-4>* {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .row-cols-xl-5>* {
            flex: 0 0 20%;
            max-width: 20%
        }
        .row.row-roomlist .row-cols-xl-6>* {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-xl-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%
        }
        .row.row-roomlist .col-xl-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%
        }
        .row.row-roomlist .col-xl-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-xl-3 {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .col-xl-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .col-xl-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%
        }
        .row.row-roomlist .col-xl-6 {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .col-xl-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%
        }
        .row.row-roomlist .col-xl-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%
        }
        .row.row-roomlist .col-xl-9 {
            flex: 0 0 75%;
            max-width: 75%
        }
        .row.row-roomlist .col-xl-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%
        }
        .row.row-roomlist .col-xl-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%
        }
        .row.row-roomlist .col-xl-12 {
            flex: 0 0 100%;
            max-width: 100%
        }
    }

    @media (min-width:1400px) {
        .row.row-roomlist .col-xxl {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-xxl-1>* {
            flex: 0 0 100%;
            max-width: 100%
        }
        .row.row-roomlist .row-cols-xxl-2>* {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .row-cols-xxl-3>* {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .row-cols-xxl-4>* {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .row-cols-xxl-5>* {
            flex: 0 0 20%;
            max-width: 20%
        }
        .row.row-roomlist .row-cols-xxl-6>* {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-xxl-auto {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%
        }
        .row.row-roomlist .col-xxl-1 {
            flex: 0 0 8.33333%;
            max-width: 8.33333%
        }
        .row.row-roomlist .col-xxl-2 {
            flex: 0 0 16.66667%;
            max-width: 16.66667%
        }
        .row.row-roomlist .col-xxl-3 {
            flex: 0 0 25%;
            max-width: 25%
        }
        .row.row-roomlist .col-xxl-4 {
            flex: 0 0 33.33333%;
            max-width: 33.33333%
        }
        .row.row-roomlist .col-xxl-5 {
            flex: 0 0 41.66667%;
            max-width: 41.66667%
        }
        .row.row-roomlist .col-xxl-6 {
            flex: 0 0 50%;
            max-width: 50%
        }
        .row.row-roomlist .col-xxl-7 {
            flex: 0 0 58.33333%;
            max-width: 58.33333%
        }
        .row.row-roomlist .col-xxl-8 {
            flex: 0 0 66.66667%;
            max-width: 66.66667%
        }
        .row.row-roomlist .col-xxl-9 {
            flex: 0 0 75%;
            max-width: 75%
        }
        .row.row-roomlist .col-xxl-10 {
            flex: 0 0 83.33333%;
            max-width: 83.33333%
        }
        .row.row-roomlist .col-xxl-11 {
            flex: 0 0 91.66667%;
            max-width: 91.66667%
        }
        .row.row-roomlist .col-xxl-12 {
            flex: 0 0 100%;
            max-width: 100%
        }
    }

    .room {
        font-family: Karla;
        display: flex;
        padding: 10px;
        box-sizing: border-box
    }

    @media (min-width:781px) {
        .room {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0
        }
    }

    /* ROOM ITEM */
    .room-item {
        display: block;
        position: relative;
        background-size: cover;
        background-position-x: center;
        background-position-y: top;
        margin-bottom: 2px;
        transition: all .2s cubic-bezier(0, 1, 1, 1);
        overflow: hidden;
        background-color: #d1d1d1
    }

    .room-item:before {
        content: "";
        display: block;
        padding-bottom: 60%
    }

    .room-item .gradient {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 50%;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, .439216), hsla(0, 0%, 100%, 0));
        transition: all .2s linear
    }

    .room-item.top .gradient {
        background-image: linear-gradient(0deg, rgba(255, 0, 135, .6), hsla(0, 0%, 100%, 0))
    }

    .room-item img.avatar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transition: all .1s
    }

    .room-item .corner.top.left {
        display: flex;
        align-items: center
    }

    .room-item .nick-and-toy {
        display: flex;
        align-items: center;
        flex-shrink: 1;
        flex-grow: 0;
        min-width: 0
    }

    .room-item .nick-and-toy .nick {
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 12px;
        color: #fff;
        font-weight: 700;
        font-weight: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
    }

    .room-item .corner {
        position: absolute;
        padding: 5px;
        transition: all .2s linear
    }

    .room-item .corner.top { top: 0 }
    .room-item .corner.bottom { bottom: 0 }
    .room-item .corner.left { left: 0 }
    .room-item .corner.right { right: 0 }

    /* END ROOM ITEM */

    /* BREADCRUM */
    .breadcrumb-bar,
    .room-item .corner.bottom.left.right {
        display: flex;
        justify-content: space-between;
        align-items: center
    }

    .breadcrumb-bar {
        background-color: #4c4c4c;
        height: 37px;
        color: #e9e9e9;
        padding: 6px 12px;
        margin-bottom: 2px;

        ol.breadcrumb .breadcrum-divider {
            margin: 0 5px
        }
        
        ol.breadcrumb a {
            text-decoration: none;
            color: inherit;
            transition: all .15s;
            display: block
        }
        
        ol.breadcrumb a:hover {
            color: #e9e9e9
        }
        
        ol.breadcrumb a svg {
            display: block
        }
        
        h1 {
            opacity: .6;
            display: inline-block;
            font-size: 14px;
            color: #e9e9e9;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            min-width: 0;
            flex-shrink: 1;
            font-weight: 700;
            letter-spacing: -.5px;
            margin: 0 0 0 1rem;
        }
    }
    /* END BREADCRUM */

}

