@import "../../../assets/styles/colors";

.products{
    .description {
        padding-top: 50px;
        h2{
            color:#781f03;
        }
        p{
            color:$orange;
        }
    }
    section{
        padding:50px 0;
    }
    section.main {
        position: relative;
        background: -moz-linear-gradient(to right, #1f1f1f, #2b2b2b);
        background: -webkit-linear-gradient(to right, #1f1f1f, #2b2b2b);
        background: linear-gradient(to right, #1f1f1f, #2b2b2b);
        //background-image: url(/images/image.jpeg);
        padding-bottom: 80px;
        padding-top: 0;
    }
    .header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 4;
        padding: 40px 0;
    }
    section.main .main-content {
        padding-top: 0px;
    }

    .menu-content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.8);
    }
    h1 {
        font-family: 'Karla', sans-serif;
        font-size: 43px;
        font-weight: bold;
        margin-bottom: 40px;
        line-height: 1.5em;
        text-align: center;
        color:#781f03;
        width:100%;
    }
    h2{
        font-family:'Karla', sans-serif;
        font-size: 20px;
        font-weight: bold;
    }
    .btn-default {
        background: $orange;
        color: $white;
    }
    @media (max-width: 991px) {
        h1 {
            font-size: 33px;
        }
        h1 {
            font-size: 26px;
        }
        .btn {
            font-size: 14px;
        }
    }
    @media (max-width: 767px) {
        .header {
            padding: 10px 0;
        }
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 21px;
        }
        .btn {
            font-size: 12px;
        }
    }
    @media (max-width: 575px) {
        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 20px;
        }
        .nav-wrapper .btn-transparent {
            font-size: 12px;
            padding:  7px 25px;
        }
    }

    .landing-sample {
        padding: 60px 220px;
        border-radius: 7px;
        transform: translateY(222px);
        color: #fff;
    }

    .landing-sample.top {
        background: url('../../../assets/img/products/vce.jpg') no-repeat center center / cover;
        transform: translateY(180px);
        p{color:$white;}
        .btn{
            background:#0b95a3;
        }
    }

    .landing-sample.bottom {
        background: url('../../../assets/img/products/adp_bg.jpg') no-repeat center center / cover;
        transform: translateY(120px);
    }

    #olecams.landing-sample {
        //background: url('../img/viewport-3.jpg') no-repeat center center / cover;
        background: url('../../../assets/img/products/oc.jpg') no-repeat center center / cover;
        transform: translateY(180px);

    }

    #olecams.landing-sample p{
        color:white;
    }

    .multibibrate {
        padding-top: 200px;
    }

    .multibibrate.orange{
        p{
            color: $orange;
        }
    }

    .multibibrate.blue{
        p{color: #1398a4;}
        h2{
            color:#02464c;
        }
    }



    @media (max-width: 991px) {
        .landing-sample .content-wrapper .sample-header .sample-menu:after,
        .landing-sample .content-wrapper .sample-header .sample-logo:after {
            width: 80px;
            height: 80px;
        }
    }

    @media (max-width: 575px) {
        .landing-sample .content-wrapper .sample-header .sample-menu:after,
        .landing-sample .content-wrapper .sample-header .sample-logo:after {
            width: 40px;
            height: 40px;
        }
        .landing-sample .content-wrapper .sample-header {
            margin-bottom: 50px;
        }
    }

    .grad-bg {
        background: #fff6db;
    }

    .grad-bg-blue {
        background: #e5fbfb;
        .btn{
            background-color: #0b95a3;
            color:$white;
        }
        .btn:hover{
            color: #0b95a3 !important;
            background-color: $white !important;
        }
    }

    section.gift{
        padding:0;
    }

    .gift .community-bg {
        background: url('../../../assets/img/products/community.svg') no-repeat center center / contain #fff;
        margin: 40px auto;
        padding: 40px 0;
    }

    .community-bg{
        h2{
            color:#02464c;
            font-size:24px;
        }
        p{color:#1398a4;}
    }

    #adptube{
        .btn{
            background-color:#da2853;
        }
    }

    .grad-bg-pink {
        padding-top:200px;
        background-color: #ffd5e0;
        h2{color:#77001b;}
        p{color:#d70636;}
    }

    .embed {
        background-color: #fdc9d6;
        padding: 70px 0;
        .container {
            padding-top: 40px;
            padding-bottom: 40px;
            background: url('../../../assets/img/products/conveyor-bg-pink.svg') no-repeat center center / contain;
        }
        p{color:#d70636;}
        .btn{
            background-color:#da2853;
        }
    }

    .subtitle{
        color: $orange;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
    }

    /**/




    .m-bot {
        margin-bottom: 50px;
    }

    .care .conveyor-icon {
        /* max-width: 300px; */
        width: 300px;
        display: block;
        margin: 0 auto 30px;
    }


    @media (max-width: 991px) {
        .landing-sample {
            padding: 33px 94px;
            transform: translateY(154px);
        }
        .landing-sample img {
            width: 70%;
        }

        p {
            font-size: 14px !important;
        }
    }

    @media (max-width: 767px) {
        .landing-sample {
            padding: 33px;
        }
        .multibibrate {
            padding-top: 200px;
        }
    }

    @media (max-width: 575px) {
        .m-bot {
            margin-bottom: 20px;
        }
        .multibibrate h2,
        .care h2 {
            text-align: center;
        }
        .multibibrate p,
        .care p,
        .description p {
            text-align: justify;
        }

        .multibibrate img {
            max-width: 60%;
            display: block;
            margin: 20px auto;
        }

        .care .conveyor-icon {
            max-width: 60%;
            margin: 20px auto 40px;
        }

        .gift .community-bg {
            padding: 0;
        }

        .landing-sample {
            padding: 30px 15px;
        }

        .landing-sample.top {
            transform: translateY(140px);
        }
        .landing-sample.bottom {
            transform: translateY(60px);
        }

        .description {
            padding-top: 100px;
        }

        .description {
            padding-top: 100px;
        }

        .description h2 {
            /* font-size: 18px; */
            text-align: center;
        }

        .description .br{
            display: none;
        }

        .description img {
            max-width: 120px;
            display: block;
            margin: 20px auto;
        }

        .embed .container {
            padding-top: 0;
            padding-bottom: 0;
            background-size: 150px;
        }

        .embed h4 {
            text-align: justify;
            font-size: 14px;
            margin-bottom: 30px;
        }
    }

}
