.noticias-container {
    .noticias-content {
        display: flex;
        @media (max-width: 600px){ flex-direction: column; }
        .left, .right { 
            width: 50%;
            @media (max-width: 600px){ width: 100%; }
            .card { 
                margin-top: 20px;
                .text-content {
                    h1 {
                        font-size: 17px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.18;
                        letter-spacing: -0.5px;
                        text-align: justify;
                        color: #4b5056;
                    }
                    p {
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.43;
                        letter-spacing: -0.5px;
                        text-align: justify;
                        color: #4b5056;
                    }
                }
                .img-content {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
            .card:first-child { margin: 0; }
        }
        .left { margin-right: 10px; }
        .right { margin-left: 10px; }
    }
}